/* Frame by Miam Studio • V1.2 */

/* 
    Globale functions
*/

Array.prototype.remove = function () {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

Array.prototype.unique = function() {
    return this.reduce (
        function (a, b) {
            if (a.indexOf(b) < 0) {
                a.push(b);
            }

            return a;

            d
        }
        ,[]
    );
};

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

function onYouTubeIframeAPIReady() {
    const event = new Event('youtubeApiReady');
    document.dispatchEvent(event);
};

(function ($) {
    let initialSortedGroup =[];
    let isYoutubeApiInsert = false;
    let isYoutubeApiLoaded = false;
    let lastScrollTop = 0;

    const minZindex = 1000,
        showAction = 'show',
        hideAction = 'hide',
        scrollAction = 'scroll',
        scrollActionDuration = 'scroll-duration';

    const numberRegex = /-{0,1}[0-9]+/, pxRegex = /px/, percentRegex = /%/, vhRegex = /vh/, urlRegex = /url\(['"]*(.*?)['"]*\)/g;

    const youtubeRegex = /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/;
    /* 
        Private functions
    */
   
    const loadYoutubeApi = function (callback) {
        if (false === isYoutubeApiInsert) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";

            $(document.body).append('<script src="https://www.youtube.com/iframe_api" />');

            isYoutubeApiInsert = true;
        }

        if (false === isYoutubeApiLoaded) {
            $(document).on('youtubeApiReady', function (event) {
                isYoutubeApiLoaded = true;
                callback();
            }); 
        } else {
            callback();
        }
    };

    const getYoutubeId = function (url) {
        return url.match(youtubeRegex)[1];
    };

    const blockScroll = function () {
        if (0 !== $('aside.dialog.show[class*="popup"]:not(.dialog\\\(scroll\\\)),aside.dialog.show[class*="alert"]:not([class*="persistent"]):not(.dialog\\\(scroll\\\)),aside.dialog.show[class*="sidebar"]:not([class*="persistent"]):not(.dialog\\\(scroll\\\))').length) {
            $('html, body').css('overflow', 'hidden');
        } else {
            $('html, body').css('overflow', '');
        }
    };

    const filterElements = function (group) {
        const $elementsInGroup = $('[data-group="' + group + '"][data-filters]'),
            $openedFiltersByName = $('[data-group="' + group + '"][data-filter].open,select[data-group="' + group + '"].open>option:selected').groupByAttr('name');

        let $elementsMustBeOpened = $elementsInGroup;

        Object.keys($openedFiltersByName).forEach( function (name) {
            const $openedFilters = $openedFiltersByName[name],
                filterForGroup = [],
                $elementsWithFilter = [];


            $openedFilters.forEach( function($openedFilter) {
                filterForGroup.push($openedFilter.data('filter').toString());
            });

            $elementsMustBeOpened.each(function (index, elementMustBeOpened) {
                const $elementMustBeOpened = $(elementMustBeOpened);

                filterForGroup.every(function (filter) {
                    if ('all' == filter) {
                        $elementsWithFilter.push($elementMustBeOpened);

                        return true;
                    }

                    if ($elementMustBeOpened.data('filters')) {
                        if ($elementMustBeOpened.data('filters').toString().split(',').includes(filter)) {
                            $elementsWithFilter.push($elementMustBeOpened);

                            return false;
                        }
                    }

                    return true;
                });            
            });

            let elementsWithFilter = [];

            $elementsWithFilter.forEach( (element) => {
                elementsWithFilter.push(element.get(0));
            })

            $elementsMustBeOpened = $(elementsWithFilter);
        });

        const hidePromises = [];
        const showPromises = [];

        $elementsInGroup.each(function (index, element) {
            const $element = $(element);

            hidePromises.push(new Promise((resolve) => {
                if (true === $element.isShow()) {
                    if ($elementsMustBeOpened.is($element)) {
                        resolve([$element, 'resolveElement']);
                    } else {
                        $element.on('hide', function() {
                            resolve([$element, true]);
                        });

                        $element.frameHide();
                    }
                } else {
                    resolve([$element, false]);
                }
            }));
        });

        $.each($elementsMustBeOpened, function (index, element) {
            const $element = $(element);

            showPromises.push(new Promise((resolve) => {
                if (false === $element.isShow()) {
                    $element.on('show', function() {
                        resolve([$element, true]);
                    });

                    $element.frameShow();
                } else {
                    resolve([$element, false]);
                }
            }));
        });

        Promise.allSettled(hidePromises).then( () => {
            Promise.allSettled(showPromises).then( () => {
                $(document).trigger('filter', [group, $elementsMustBeOpened]);
            });
        });
    };
   
    const filterSelect = function ($element) {
        const group = $element.data('group');

        if (false === $element.hasClass('multiple')) {
            $('[data-group="' + group + '"][data-filter].open').removeClass('open');
            $('input[type="checkbox"][data-group="' + group + '"][data-filter].open').prop('checked', false);
        } else {
            $('[data-group="' + group + '"][data-filter="all"].open').removeClass('open');
        }

        if ('' !== $element.val()) {
            $element.addClass('open');
        } else {
            $element.removeClass('open');
        }

        filterElements(group);
    };

    const sortElements = function (group) {
        const $elementsInGroup = $.extend({},  initialSortedGroup[group]);

        let activeSorts = [];

        $('[data-group="' + group + '"][data-sort].open, select[data-group="' + group + '"].open option[data-sort]:selected').sortByData('index').each(function (index, element) {
            const $element = $(element);

            activeSorts.push({ sort: $(element).data('sort'), direction: $(element).data('direction') ? $(element).data('direction') : 'asc'});
        });

        if (activeSorts.length > 0) {
            $('[data-group="' + group + '"][data-sorts]').parent().append($elementsInGroup.sortBy(activeSorts));
        } else {
            $('[data-group="' + group + '"][data-sorts]').parent().append(initialSortedGroup[group]);
        }
    };
   
    // Handler for click outside event for dialog element
    const dialogClickOutsideHandler = function (event) {
        const $containers = $('aside.dialog.show:not(.filter,.collapse,.tab,.dialog\\\(mandatory\\\))').sortByZindex().children('div:first-of-type'),
            $containersMandatory = $('aside.dialog.show.dialog\\\(mandatory\\\):not(.filter,.collapse,.tab)').children('div:first-of-type'),
            $container = $($containers[0]);
        if (false == $container.is(event.target) && 0 === $container.has(event.target).length && false == $containersMandatory.is(event.target) && 0 === $containersMandatory.has(event.target).length && undefined === $(event.target).data(hideAction)) {
            $container.parent('aside').frameHide();
            $container.find('[data-name]:not(.filter,.collapse,.tab)').frameHide();
        }
    };

    // Handler for click event for animated element
    const effectOnClickHandler = function (event) {
        let $element = $(event.target);

        if (false === $element.hasClass('click/effect')) {
            $element = $element.parents('.click\\\/effect');
        }

        if (false === $element.hasClass('animation')) {
            $element.addClass('animation');

            $element.on('animationend', function () {
                $element.removeClass('animation');

                event.stopPropagation();
            });
        }

    };

    const scrollHandler = function (event) {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight,
            windowScrollTop = window.scrollY,
            scrollTopDifference = windowScrollTop - lastScrollTop;


        // animation loaded in fuction of scroll
        $('.scroll\\\/effect').each(function (index, element) {
            const $element = $(element),
                elementOffset = $element.offset();
            let scrollTop;

            if (false == $element.isVisible()) {
                $element.data('scroll', null);
                return;
            }

            $element.data('scroll', ($element.data('scroll') == undefined ? 0 : $element.data('scroll')) + scrollTopDifference);

            percent = $element.data('scroll') / viewportHeight;

            if (percent > 0) {
                $element.css('animation-delay',  - (parseInt(percent * 1000)) + 'ms');
            } else {
                $element.css('animation-delay',  - (parseInt((1 + percent) * 1000)) + 'ms');
            }
        });

        // video loaded in fuction of scroll
        $('video.scroll\\\/video').each(function (index, element) {
            const $element = $(element),
                elementOffset = $element.offset();

            let elementHeight = $element.data('height');

            if (false == $element.isVisible()) {
                return;
            }

            if (!elementHeight) {
                elementHeight = $element.get(0).getBoundingClientRect().height;
                $element.data('height', elementHeight);
            }

            if (elementOffset.top < (windowScrollTop + viewportHeight) && elementOffset.top + elementHeight > windowScrollTop) {
                element.pause();

                if (elementOffset.top < windowScrollTop) {
                    if (element.currentTime !== 0) {
                        element.currentTime = element.duration - 0.100;
                    }
                } else {
                    element.currentTime = (Math.round( 1000 * element.duration * (1 - (elementOffset.top - windowScrollTop) / viewportHeight)) / 1000)
                }
            }
        });

        // play video if in viewport
        $('video.scroll\\\/video\\\(play\\\)').each(function (index, element) {
            const $element = $(element),
                elementOffset = $element.offset();

            let elementHeight = $element.data('height');

            if (false == $element.isVisible()) {
                element.pause();
                element.currentTime = 0;
                return;
            }

            element.play();

            // if (!elementHeight) {
            //     elementHeight = $element.get(0).getBoundingClientRect().height;
            //     $element.data('height', elementHeight);
            // }

            // if (elementOffset.top < (windowScrollTop + viewportHeight) && elementOffset.top + elementHeight > windowScrollTop) {
            //     element.pause();

            //     if (elementOffset.top < windowScrollTop) {
            //         if (element.currentTime !== 0) {
            //             element.currentTime = element.duration - 0.100;
            //         }
            //     } else {
            //         element.currentTime = (Math.round( 1000 * element.duration * (1 - (elementOffset.top - windowScrollTop) / viewportHeight)) / 1000)
            //     }
            // }
        });


        $('.position\\\(sticky\\\)').each(function (index, element) {
            const $element = $(element),
                top = $element.data('initial-top');

            let limit = windowScrollTop;
            if ($element.data('method') !== "headerInside") {
                $(($element.is('header') ? '' : 'body > header,') + '.persistent\\\(top\\\) > div:first-of-type').each(function (index, elementTop) {
                    const $elementTop = $(elementTop);
    
                    limit += $elementTop.outerHeight();
                });
            }

            if (top <= limit) {

                if (false === $element.hasClass('sticked')) {
                    const uuid = uuidv4(), 
                        div = $('<div data-uuid="' + uuid + '" />');

                    div.addClass($element[0].className.replace('position(sticky)', ''));
                    div.cssImportant('visibility', 'hidden');

                    div.data('uuid', uuid);
                    div.insertBefore($element);
                    $element.data('placeholder', uuid);
                }

                $('[data-uuid="' + $element.data('placeholder') + '"]')

                let top = 0;
                if ($element.data('method') !== "headerInside") {
                    $(($element.is('header') ? '' : 'body > header,') + '.persistent\\\(top\\\) > div:first-of-type').each(function (index, elementTop) {
                        const $elementTop = $(elementTop);

                        if ($elementTop.isInViewport()) {
                            top += $elementTop.outerHeight(); 
                        }
                    });
                }

                $element
                    .cssImportant('position', 'fixed')
                    .cssImportant('top', top + 'px')
                    .addClass('sticked')
                ;

            } else {
                $element
                    .css('position', '')
                    .css('top', '')
                    .removeClass('sticked')
                ;

                $('[data-uuid="' + $element.data('placeholder') + '"]').remove();
            }
        });

        // infinite scroll
        $('.scroll\\\/infinite').each(function (index, element) {
            const $element = $(element),
                elementEnd = $element.offset().top + $element.height();

            if (false == $element.isVisible()) {
                return;
            }

            if (windowScrollTop <= elementEnd && elementEnd <= (windowScrollTop + viewportHeight) && $element.data('loading') != '1') {
                $element.data('loading', '1');

                var data = $element.data('parameters') ? $element.data('parameters') : {},
                    page = $element.data('page') ? parseInt($element.data('page')) + 1 : 1,
                    pageAttribute = $element.data('page-attribute') ? $element.data('page-attribute') : 'page';

                data[pageAttribute] = page;
                $element.data('page', page);

                $.ajax({
                    url: $element.data('url'),
                    dataType: 'html',
                    method: $element.data('method') ? $element.data('method') : 'GET',
                    data: data,
                    context: $element,
                }).done(function (data) {
                    $(this).append($(data));
                    $.frameInitializeEvents();

                }).fail(function (error) {
                    console.error(error);
                }).always(function () {
                    $(this).data('loading', '0');
                });
            }
        });

        // play action on target
        playActionOnTarget();

        lastScrollTop = windowScrollTop;
    };

    const addHash = function (hash) {
        const actualHash = window.location.hash.substr(1);

        if ('' !== actualHash) {
            var hashes = actualHash.split(',');

            if (-1 === hashes.indexOf(hash)) {
                hashes.push(hash);

                return '#' + hashes.join(',');
            }

            return '#' + actualHash;
        } else {
            return '#' + hash;
        }
    };

    const removeHash = function (hash) {
        const actualHash = window.location.hash;

        if ('' !== actualHash) {
            var hashes = actualHash.substr(1).split(',');

            if (-1 !== hashes.indexOf(hash)) {
                hashes.remove(hash);

                if (0 !== hashes.length) {
                    return '#' + hashes.join(',');
                }

                return '';
            }
        }

        return actualHash;
    };

    const humanTimeToMillisecond = function (humanTime) {
        if (-1 !== humanTime.indexOf('ms')) {
            return parseInt(humanTime);
        } else if (-1 !== humanTime.indexOf('s')) {
            return parseInt(humanTime) * 1000;
        }

        return false;
    };

    const pixelToInt = function (pixel) {
        if ('none' === pixel) {
            return 0;
        } else if (-1 !== pixel.indexOf('px')) {
            return parseInt(pixel);
        }

        return false;
    };

    const calculatePersistent = function () {

        // initaliser les varibles pour les différents padding, width et height à 0
        // incrementer les valeurs
        // appliquer le css si > 0

        
        $('.dialog.show.persistent\\\(top\\\)[class*="alert"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-top', $elementDiv.outerHeight(false) + 'px');
                $('header').cssImportant('top', $elementDiv.outerHeight(false) + 'px');
                $('.height\\\(full\\\)')
                    .cssImportant('height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                    .cssImportant('min-height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                ;
            } else {
                $('main').css('padding-top', '');
                $('header')
                    .css('top', '')
                ;
                $('.height\\\(full\\\)')
                    .css('height', '')
                    .css('min-height', '')
                ;
            }
        });

        $('.dialog.show.persistent\\\(bottom\\\)[class*="alert"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-bottom', $elementDiv.outerHeight(false) + 'px');
                $('footer').cssImportant('margin-bottom', $elementDiv.outerHeight(false) + 'px');
                $('.height\\\(full\\\)')
                    .cssImportant('height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                    .cssImportant('min-height', ($window.height() - $elementDiv.outerHeight(false)) + 'px')
                ;
            } else {
                $('main').css('padding-bottom', '');
                $('footer').css('margin-bottom', '');
                $('header')
                    .css('height', '')
                    .css('min-height', '')
                ;
            }
        });

        $('.dialog.show.persistent\\\(left\\\)[class*="sidebar"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-left', $elementDiv.outerWidth(false) + 'px');
                $('header')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('right', '0px')
                ;
                $('.width\\\(full\\\)')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('min-width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                ;
            } else {
                $('main').css('padding-left', '');
                $('header')
                    .css('width', '')
                    .css('right', '')
                ;
                $('.width\\\(full\\\)')
                    .css('width', '')
                    .css('min-width', '')
                ;
            }
        });

        $('.dialog.show.persistent\\\(right\\\)[class*="sidebar"]').each(function (index, element) {
            const $element = $(element),
                $elementDiv = $element.children('div:first-of-type'),
                $window = $(window);

            if ($element.isVisible()) {
                $('main').cssImportant('padding-right', $elementDiv.outerWidth(false) + 'px');
                $('header')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('left', '0px')
                ;
                $('.width\\\(full\\\)')
                    .cssImportant('width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                    .cssImportant('min-width', ($window.width() - $elementDiv.outerWidth(false)) + 'px')
                ;
            } else {
                $('main').css('padding-right', '');
                $('header')
                    .css('width', '')
                    .css('left', '')
                ;
                $('.width\\\(full\\\)')
                    .css('width', '')
                    .css('min-width', '')
                ;
            }
        });
    };

    const playActionOnTarget = function () {
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight,
            windowScrollTop = window.scrollY;

        // action on target

        $.each($('[data-target][data-method][data-action]').groupBy('target'), function (targetName, $elements) {
            const $target = $('[data-name="' + targetName + '"]'),
                targetRect = $target[0].getBoundingClientRect();

            let elementsToShow = [],
                elementsToHide = [],
                classesToAdd = [],
                classesToRemove = [];

            $.each($elements, function (index, $element) {
                const elementRect = $element[0].getBoundingClientRect(),
                    method = $element.data('method'),
                    classes = [],
                    revertClasses = [],
                    showes = [],
                    revertShowes = [],
                    hides = [],
                    revertHides = [];


                $.each($element.data('action').split('|'), function (index, actions) {
                    if (0 == index)  {
                        $.each(actions.split(','), function (index, action) {
                            classes.push(action);
                        });
                    } else if (1 == index) {
                        $.each(actions.split(','), function (index, action) {
                            revertClasses.push(action);
                        });
                    }
                });

                if (undefined !== $element.data(showAction)) {
                    $.each($element.data(showAction).split('|'), function (index, actions) {
                        if (0 == index)  {
                            $.each(actions.split(','), function (index, action) {
                                showes.push(action);
                            });
                        } else if (1 == index) {
                            $.each(actions.split(','), function (index, action) {
                                revertShowes.push(action);
                            });
                        }
                    });
                }

                if (undefined !== $element.data(hideAction)) {
                    $.each($element.data(hideAction).split('|'), function (index, actions) {
                        if (0 == index)  {
                            $.each(actions.split(','), function (index, action) {
                                hides.push(action);
                            });
                        } else if (1 == index) {
                            $.each(actions.split(','), function (index, action) {
                                revertHides.push(action);
                            });
                        }
                    });
                }


                function action(normal, classes, revertClasses, showes, revertShowes, hides, revertHides, classesToAdd, classesToRemove, elementsToShow, elementsToHide) {
                    if (true === normal) {
                        $.each(classes, function (index, classCss) {
                            if (-1 === classesToAdd.indexOf(classCss)) {
                                classesToAdd.push(classCss);
                            }
                        });

                        $.each(revertClasses, function (index, classCss) {
                            if (-1 === classesToRemove.indexOf(classCss)) {
                                classesToRemove.push(classCss);
                            }
                        });

                        $.each(showes, function (index, show) {
                            if (-1 === elementsToShow.indexOf(show)) {
                                elementsToShow.push(show);
                            }
                        });

                        $.each(hides, function (index, hide) {
                            if (-1 === elementsToHide.indexOf(hide)) {
                                elementsToHide.push(hide);
                            }
                        });
                    } else {
                        $.each(classes, function (index, classCss) {
                            if (-1 === classesToRemove.indexOf(classCss)) {
                                classesToRemove.push(classCss);
                            }
                        });

                        $.each(revertClasses, function (index, classCss) {
                            if (-1 === classesToAdd.indexOf(classCss)) {
                                classesToAdd.push(classCss);
                            }
                        });

                        $.each(revertShowes, function (index, show) {
                            if (-1 === elementsToShow.indexOf(show)) {
                                elementsToShow.push(show);
                            }
                        });

                        $.each(revertHides, function (index, hide) {
                            if (-1 === elementsToHide.indexOf(hide)) {
                                elementsToHide.push(hide);
                            }
                        });
                    }
                }

                switch (method) {
                    case 'elementInside':
                        action(((elementRect.height > 0 || elementRect.width > 0) && targetRect.top >= elementRect.top && targetRect.top + targetRect.height <= elementRect.top + elementRect.height), classes, revertClasses, showes, revertShowes, hides, revertHides, classesToAdd, classesToRemove, elementsToShow, elementsToHide);
                        break;
                    case 'elementPartial':
                        action(((elementRect.height > 0 || elementRect.width > 0) && targetRect.top <=  elementRect.top + elementRect.height && targetRect.top + targetRect.height >= elementRect.top), classes, revertClasses, showes, revertShowes, hides, revertHides, classesToAdd, classesToRemove, elementsToShow, elementsToHide);
                        break;
                    case 'elementOutside':
                        action(false === ((elementRect.height > 0 || elementRect.width > 0) && targetRect.top >= elementRect.top && targetRect.top + targetRect.height <= elementRect.top + elementRect.height), classes, revertClasses, showes, revertShowes, hides, revertHides, classesToAdd, classesToRemove, elementsToShow, elementsToHide);
                        break;
                    case 'viewportInside':
                        action(((elementRect.height > 0 || elementRect.width > 0) && elementRect.top >= 0 && elementRect.top + elementRect.height <= viewportHeight), classes, revertClasses, showes, revertShowes, hides, revertHides, classesToAdd, classesToRemove, elementsToShow, elementsToHide);
                        break;
                    case 'viewportPartial':
                        action(((elementRect.height > 0 || elementRect.width > 0) && elementRect.top <= viewportHeight && elementRect.top + elementRect.height >= 0), classes, revertClasses, showes, revertShowes, hides, revertHides, classesToAdd, classesToRemove, elementsToShow, elementsToHide);
                        break;
                    case 'viewportOutside':
                        action((false === (elementRect.height > 0 || elementRect.width > 0) && elementRect.top >= 0 && elementRect.top + elementRect.height <= viewportHeight), classes, revertClasses, showes, revertShowes, hides, revertHides, classesToAdd, classesToRemove, elementsToShow, elementsToHide);
                        break;
                    default:
                        return console.error('method ' + method + ' not allowed');
                } // switch (method)
            }); // $.each($elements, function (index, $element)

            classesToAdd = classesToAdd.unique();
            classesToRemove = classesToRemove.unique();

            $.each(classesToRemove, function (index, classToRemove) {
                if (-1 !== classesToAdd.indexOf(classToRemove)) {
                    classesToRemove.remove(classToRemove);
                }
            });

            $target.removeClass(classesToRemove.join(' ')).addClass(classesToAdd.join(' '));

            elementsToShow = elementsToShow.unique();
            elementsToHide = elementsToHide.unique();

            $.each(elementsToHide, function (index, elementToHide) {
                if (-1 !== elementsToShow.indexOf(elementToHide)) {
                    elementsToHide.remove(elementToHide);
                }
            });

            $.each(elementsToShow, function (index, elementToShow) {
                $elementToShow = $('[data-name="' + elementToShow + '"]');

                if (false === $elementToShow.hasClass('show') && true !== $elementToShow.data('show-target')) {
                    $elementToShow.frameShow();
                }
            });

            $.each(elementsToHide, function (index, elementToHide) {
                $elementToHide = $('[data-name="' + elementToHide + '"]');

                if (true === $elementToHide.hasClass('show')) {
                    $elementToHide.frameHide();
                }
            });
        });
    };

    /* 
        Jquery functions
    */

    $.playActionOnTarget = function() {
        playActionOnTarget();
    };

    $.frameInitialize = function () {
        $.frameInitializeSliders();
        $.frameInitializeVideos();

        $('aside.dialog.load\\\/dialog:not(aside.dialog.tab),.slide.load\\\/dialog').frameShow();


        if (window.location.hash) {
            const hashes = window.location.hash.substr(1);

            $(hashes.split(',')).each(function (index, hash) {
                if (-1 === hash.indexOf('=')) {
                    const $element = $('[data-name="' + hash + '"]');

                    if ($element.hasClass('tab')) {
                        const $otherTabsinGroups = $('.dialog.tab.show[data-group="' + $element.data('group') + '"]').not($element);

                        $otherTabsinGroups.removeClass('load/dialog');
                        $element.addClass('load/dialog');

                        $element.parents('section').frameScrollTo();
                    } else {
                        $element.frameShow();
                    }
                }
            });
        }

        // Initialize CSS Class auto
        $.frameClassAuto();
          
        const motionObserver = new IntersectionObserver(function (entries) {
            entries.forEach( function (entry) {
                if (true === entry.isIntersecting) {
                    const $element = $(entry.target);

                    $element.startMotion();
                } else {
                    const $element = $(entry.target);

                    if ($element.hasClass('motion(repeat)')) {
                        const motionParameters = $element.classParameters('motion');

                        $element
                            .removeClass('animation animation(end)')
                        ;

                        motionParameters.forEach( function (motionParameter) {
                            $element.removeClass('motion(' + motionParameter + ')');
                        });
                        
                        window.requestAnimationFrame(function(time) {
                            window.requestAnimationFrame(function(time) {
                                motionParameters.forEach( function (motionParameter) {
                                    $element.addClass('motion(' + motionParameter + ')');
                                });
                            });
                        });
                    }

                    $element.find('.motion\\\(repeat\\\)').each(function (index, child) {
                        const $child = $(child),
                            motionParameters = $child.classParameters('motion');

                        $child
                            .removeClass('animation animation(end)')
                        ;

                        motionParameters.forEach( function (motionParameter) {
                            $child.removeClass('motion(' + motionParameter + ')');
                        });
                        
                        window.requestAnimationFrame(function(time) {
                            window.requestAnimationFrame(function(time) {
                                motionParameters.forEach( function (motionParameter) {
                                    $child.addClass('motion(' + motionParameter + ')');
                                });
                            });
                        });
                    });
                }
            });
        }, {
            //root: document,
            rootMargin: '0px',
            threshold: 0
        });

        $('[class*="motion("]').each(function (index, element) {
            const $element = $(element);

            if (0 === $element.parents('[class*="motion("]').length  && false === $element.hasClass('slide') && 0 === $element.parents('.slide').length && false === $element.is('aside.dialog') && 0 === $element.parents('aside.dialog').length) {
                motionObserver.observe(element);
            }
        });

        const sliderObserver = new IntersectionObserver(function (entries) {
            entries.forEach( function (entry) {
                const $element = $(entry.target);
                if (true === entry.isIntersecting ) {
                    const $element = $(entry.target);
        
                    $element.startMotion();

                    $element.data('observer', new IntersectionObserver(function (entries) {
                        entries.forEach( function (entry) {

                            if (true === entry.isIntersecting) {
                                const $element = $(entry.target);
                    
                                $element.startMotion();
                            } else {
                                const $element = $(entry.target);
            
                                if ($element.hasClass('motion(repeat)')) {
                                    const motionParameters = $element.classParameters('motion');
            
                                    $element
                                        .removeClass('animation animation(end)')
                                    ;
            
                                    motionParameters.forEach( function (motionParameter) {
                                        $element.removeClass('motion(' + motionParameter + ')');
                                    });
                                    
                                    window.requestAnimationFrame(function(time) {
                                        window.requestAnimationFrame(function(time) {
                                            motionParameters.forEach( function (motionParameter) {
                                            $element.addClass('motion(' + motionParameter + ')');
                                            });
                                        });
                                    });
                                }
            
                                $element.find('.motion\\\(repeat\\\)').each(function (index, child) {
                                    const $child = $(child),
                                        motionParameters = $child.classParameters('motion');
            
                                    $child
                                        .removeClass('animation animation(end)')
                                    ;
            
                                    motionParameters.forEach( function (motionParameter) {
                                        $child.removeClass('motion(' + motionParameter + ')');
                                    });
                                    
                                    window.requestAnimationFrame(function(time) {
                                        window.requestAnimationFrame(function(time) {
                                            motionParameters.forEach( function (motionParameter) {
                                                $child.addClass('motion(' + motionParameter + ')');
                                            });
                                        });
                                    });
                                });
                            }
                        });
                    }, {
                        //root: document,
                        rootMargin: '0px',
                        threshold: 1
                    }));
    
                    $element.find('[class*="motion("]').each(function (index, child) {
                        const $child = $(child),
                            level = ($element.is('[class*="motion("]') ? $element.parents('[class*="motion("]').length + 1 : $element.parents('[class*="motion("]').length);
    
                        if (level === $child.parents('[class*="motion("]').length) {
                            $element.data('observer').observe(child);
                        }
                    });

                } else {
                    $element.find('.motion\\\(repeat\\\)').each(function (index, child) {
                        const $child = $(child),
                            motionParameters = $child.classParameters('motion');

                        if (undefined !== $element.data('observer')) {
                            $element.data('observer').disconnect();
                            $element.data('observer', null);
                        }

                        $child
                            .removeClass('animation animation(end)')
                            .css('animation-delay', '');
                        ;

                        motionParameters.forEach( function (motionParameter) {
                            $child.removeClass('motion(' + motionParameter + ')');
                        });
                        
                        window.requestAnimationFrame(function(time) {
                            motionParameters.forEach( function (motionParameter) {
                                $child.addClass('motion(' + motionParameter + ')');
                            });
                        });
                    });
                }
            });
        }, {
            //root: document,
            rootMargin: '0px',
            threshold: 0
        });

        $('.slide').each(function (index, element) {
            const $element = $(element);

            if (0 === $element.parents('.slider.overflow\\\(none\\\)').length) {
                sliderObserver.observe(element);
            }
        });

        // playActionOnTarget on targets animation end
        let targets = [],
            promises = [];

        $('[data-target]').each(function (index, opener) {
            const $opener = $(opener);

            targets.push($opener.data('target'));
        })

        targets = targets.unique();

        $('[data-name="' + targets.join('"],[data-name="') + '"]').each(function (index, element) {
            const $element = $(element);

            if ($element.is('[class*="motion("]') && $element.isInViewport()) {
               promises.push($.Deferred(function(defer) {
                    $element.on('animationend', function (event) {
                        defer.resolve(event);
                        event.stopPropagation();
                    });
                }).promise().then());
            }
        });

        $.when.apply(null, promises).done(function() {
            playActionOnTarget();
        });

        // Initale top for element sticky
        $('.position\\\(sticky\\\)').each(function (index, element) {
            const $element = $(element);

            if ($element.is('[class*="motion("]')) {
                $element.on('animationend', function (event) {
                    const elementRect = $element[0].getBoundingClientRect();
                    windowScrollTop = window.scrollY;

                    $element.data('initial-top', windowScrollTop + elementRect.top);
                    event.stopPropagation();
                });
            }
        });

         // Initialize Events
        $.frameInitializeEvents();

        // Filter on load
        $.frameFilter();

        // Filter on load
        $.frameSort();

        // initialize tab
        let tabGroups = {};
        let tabGroupsAutoplay = {};
        $('.dialog.tab[data-group]').each(function (index, tab) {
            const $tab = $(tab),
                group = $tab.data('group');

            if(0 !== $tab.parents('aside').length){
                return
            }

            if (typeof tabGroups[group] === 'undefined') {
                tabGroups[group] = [$tab];
            } else {
                tabGroups[group].push($tab);
            }

            if ($tab.classStart('autoplay(') || typeof tabGroupsAutoplay[group] !== 'undefined') {
                if (typeof tabGroupsAutoplay[group] === 'undefined') {
                    tabGroupsAutoplay[group] = [$tab];
                } else {
                    tabGroupsAutoplay[group].push($tab);
                }
            }
        });

        $.each(tabGroups, function (group, tabs) {
            let indexToOpen = 0;

            for (let i = 0; i < tabs.length; i++) {
                if (tabs[i].hasClass('load/dialog')) {
                    indexToOpen = i;
                    break;
                }
            }
            tabs[indexToOpen].frameShow();
        });

        $.each(tabGroupsAutoplay, function (group, tabs) {
            if (tabs.length > 1) {
                const $firstTab = $(tabs[0]),
                    autoplayClass = $firstTab.classParameters('autoplay');
                
                

                if (autoplayClass.length > 0 && humanTimeToMillisecond(autoplayClass[0])) {
                    const delay = humanTimeToMillisecond(autoplayClass[0]);

                    window.setInterval(function (tabs) {
                        let lastIndex = 0;

                        $.each(tabs, function (index, tab) {
                            if (tab.hasClass('show')) {
                                lastIndex = index;
                            }
                        });

                        tabs[lastIndex]
                            .on('hide', function(event) {
                                tabs[lastIndex].off(event);
                                tabs[lastIndex + 1 == tabs.length ? 0 : lastIndex + 1].frameShow();

                            })
                            .frameHide()
                        ;
                    }, delay, tabs);
                }
            }

            return;
        });

        // Check if all data-name are unique
        const dataNames = [];

        $('[data-name]').each(function (index, element) {
            const dataName = $(element).data('name');

            if (-1 === dataNames.indexOf(dataName)) {
                dataNames.push(dataName);
            } else {
                console.error('Element with data-name="' + dataName + '" allready exists! Parameter data-name must be unique.');
            }
        });

        // Initale top for element sticky
        $('.position\\\(sticky\\\)').each(function (index, element) {
            const $element = $(element);

            if (false === $element.is('[class*="motion("]')) {
                 elementRect = $element[0].getBoundingClientRect();
                windowScrollTop = window.scrollY;

                $element.data('initial-top', windowScrollTop + elementRect.top);
            }
        });

        $(document).trigger('init');
    };

    $.sortSliders = function () {
        const sortByTarget = function (a, b) {
            const $a = $(a),
                $b = $(b),
                dataNameA = $a.data('name'),
                dataTargetA = $a.data('target'),
                dataNameB = $b.data('name'),
                dataTargetB = $b.data('target');

            if (dataTargetA == undefined) {
                return 1;
            } else if (dataNameA == undefined) {
                return -1
            } else if (dataTargetA == dataNameB) {
                return 1;
            } else  {
                return 0;
            }
        };

        return $('.slider').sort(sortByTarget);
    };

    $.frameInitializeSliders = function () {
        $.sortSliders().each(function (index, element) {
            $(element).slider();
        });

        $.slidersSetContols();
    };

    $.slidersSetContols = function () {
        const $sliders = $('.slider[data-control]');

        $sliders.each(function (index, slider) {
            const $slider = $(slider),
                controlledSwipers = [];

            $slider.data('control').toString().split(',').forEach( function (target) {
                const $target = $('.slider[data-name="' + target + '"]');

                if ($target.length) {
                    controlledSwipers.push($target[0].swiper);
                }
            });


            slider.swiper.controller.control = controlledSwipers;

            // slider.swiper.params.controller = {
            //     by: 'slide',
            //     control: controlledSwipers,
            //     inverse: false,
            // };

            slider.swiper.update();
        });
    }

    $.frameInitializeVideos = function () {
        $('video').each(function (index, element) {
            const $element = $(element);

            $element.video();
        });
    };

    // Stop event handler et add evet handler
    $.frameInitializeEvents = function () {

        var evCache = new Array();
        var prevDiff = -1;

        function pointerdown_handler(ev) {
         // L'événement pointerdown signale le début d'une interraction de toucher.
         // L'événement est mis en cache pour prendre en charge les gestes à 2 doigts
         evCache.push(ev);

        }

        function pointermove_handler(ev) {
         // Cette fonction implémente la détection du mouvement horizontal pincer/zoomer.
         //
         // Si la distance entre les deux pointeurs augmente (zoomer), 
         // l'arrière-plan de l'élément cible est changé en "pink" et si la 
         // distance diminue (dezoomer), la couleur est changée en "lightblue".
         //
         // Cette fonctionne définie la bordure de l'élément cible à "dashed" pour indiquer
         // visuellement que la cible du pointeur a reçu un événement de déplacement.

         ev.target.style.border = "dashed";

         // Trouve le pointeur en cours dans le cache et le met à jour avec cet événement
         for (var i = 0; i < evCache.length; i++) {
           if (ev.pointerId == evCache[i].pointerId) {
              evCache[i] = ev;
              break;
           }
         }

         // Si deux pointeurs sont utilisés, vérifie le geste de pincement
         if (evCache.length == 2) {
           // Calcule la distance entre les deux pointeurs
           var curDiff = Math.abs(evCache[0].clientX - evCache[1].clientX);

           if (prevDiff > 0) {
             if (curDiff > prevDiff) {
               // La distance entre les deux pointeurs a augmenté

               ev.target.style.background = "pink";
             }
             if (curDiff < prevDiff) {
               // La distance entre les deux pointeurs a diminué

               ev.target.style.background = "lightblue";
             }
           }

           // Met en cache la distance pour les événements suivants
           prevDiff = curDiff;
         }
        }
        function pointerup_handler(ev) {

          // Retire ce pointeur du cache et rétablit l'arrière-plan et
          // et bordure de la cible
          remove_event(ev);
          ev.target.style.background = "white";
          ev.target.style.border = "1px solid black";
         
          // Si le nombre de pointeurs restant est inférieur à deux, remet à zéro la différence
          if (evCache.length < 2) prevDiff = -1;
        }

        function remove_event(ev) {
         // Supprime l'événement du cache
         for (var i = 0; i < evCache.length; i++) {
           if (evCache[i].pointerId == ev.pointerId) {
             evCache.splice(i, 1);
             break;
           }
         }
        }

        $(document)
            // Delete all event
            .off('.frame')

            // Show on click
            .on('click.frame', '[data-' + showAction + ']:not([data-target][data-action][data-method]):not(.slider.slide\\\(click\\\) *)', function (event) {
                const $self = $(this);
                
                $($self.data(showAction).toString().split(',')).each(function (index, dataShow) {
                    const $element = $('[data-name="' + dataShow + '"]'),
                        open = $self.hasClass('open');

                    if (open && $element.hasClass('collapse')) {
                        $element.frameHide();
                        event.stopPropagation();
                    } else {
                        if ($element.hasClass('tab')) {
                            const $otherTabsinGroups = $('.dialog.tab.show[data-group="' + $element.data('group') + '"]').not($element);

                            if ($otherTabsinGroups.length === 0) {
                                $element.frameShow();
                            } else {
                                $otherTabsinGroups.on('hide', function (event) {
                                    $element.frameShow();
                                    $otherTabsinGroups.off(event);
                                });
    
                                $otherTabsinGroups.frameHide();
                            }

                        } else if ($element.hasClass('collapse')) {
                            const $otherCollapsesInGroup = $('.dialog.collapse.show[data-group="' + $element.data('group') + '"]').not($element);
                            if ($otherCollapsesInGroup.length > 0) {
                                $otherCollapsesInGroup
                                    .on('hide', function () {
                                        $(event.target).off('hide');
                                        $element.frameShow();
                                    })
                                    .frameHide()
                                ;
                            } else {
                                $('.dialog.collapse.show[data-group="' + $element.data('group') + '"]').off('hide');
                                $element.frameShow();
                            }
                        } else {
                            $element.frameShow();
                        }
                        
                        event.stopPropagation();
                    }
                });
            })

            // Hide on Click
            .on('click.frame', '[data-' + hideAction + ']:not([data-' + scrollAction + ']):not([data-target][data-action][data-method])', function (event) {
                $($(this).data(hideAction).toString().split(',')).each(function (index, dataHide) {
                    $('[data-name="' + dataHide + '"]').frameHide();
                });

                event.stopPropagation();
            })

            .on('click.frame', '[data-target][data-action]:not([data-method])', function (event) {
                const $element = $(event.target),
                    $target = $('[data-name="' + $element.data('target') + '"]'),
                    actionIndex = parseInt($element.data('action-index') ?? 0),
                    classes = [],
                    revertClasses = [];
                
                let classesToAdd = [],
                    classesToRemove = [],
                    actionCount = 0;

                $.each($element.data('action').split('|'), function (index, actions) {
                    if (actionIndex == index)  {
                        $.each(actions.split(','), function (index, action) {
                            classes.push(action);
                        });
                    } else {
                        $.each(actions.split(','), function (index, action) {
                            revertClasses.push(action);
                        });
                    }

                    actionCount++;
                });

                $.each(classes, function (index, classCss) {
                    if (-1 === classesToAdd.indexOf(classCss)) {
                        classesToAdd.push(classCss);
                    }
                });

                $.each(revertClasses, function (index, classCss) {
                    if (-1 === classesToRemove.indexOf(classCss)) {
                        classesToRemove.push(classCss);
                    }
                });

                classesToAdd = classesToAdd.unique();
                classesToRemove = classesToRemove.unique();

                $.each(classesToRemove, function (index, classToRemove) {
                    if (-1 !== classesToAdd.indexOf(classToRemove)) {
                        classesToRemove.remove(classToRemove);
                    }
                });

                $target.removeClass(classesToRemove.join(' ')).addClass(classesToAdd.join(' '));

                $element.data('action-index', actionIndex + 1 < actionCount ? actionIndex + 1 : 0);
                event.stopPropagation();
            })

            // Show on mouseover
            .on('mouseover.frame, touchstart.frame', '[data-' + showAction + '].hover\\\/dialog,[data-' + hideAction + '].hover\\\/dialog', function (event) {
                const $self = $(this);

                if ($self.data(showAction)) {
                    $($self.data(showAction).toString().split(',')).each(function (index, dataShow) {
                        const $element = $('[data-name="' + dataShow + '"]');

                        if (false === $element.isShow()) {
                            $element.frameShow();
                        }
                    });
                }

                if ($self.data(hideAction)) {
                    $($self.data(hideAction).toString().split(',')).each(function (index, dataHide) {
                        const $element = $('[data-name="' + dataHide + '"]');

                        if (true === $element.isShow()) {
                            $element.frameHide();
                        }
                    });
                }

                event.stopPropagation();
            })

            .on('mouseover.frame, touchstart.frame', '[data-target][data-action].hover\\\/dialog:not([data-method])', function (event) {
                const $element = $(event.target),
                    $target = $('[data-name="' + $element.data('target') + '"]'),
                    actionIndex = parseInt($element.data('action-index') ?? 0),
                    classes = [],
                    revertClasses = [];
                
                let classesToAdd = [],
                    classesToRemove = [],
                    actionCount = 0;

                $.each($element.data('action').split('|'), function (index, actions) {
                    if (0 == index)  {
                        $.each(actions.split(','), function (index, action) {
                            classes.push(action);
                        });
                    } else if(1 == index) {
                        $.each(actions.split(','), function (index, action) {
                            revertClasses.push(action);
                        });
                    }

                    actionCount++;
                });

                $.each(classes, function (index, classCss) {
                    if (-1 === classesToAdd.indexOf(classCss)) {
                        classesToAdd.push(classCss);
                    }
                });

                $.each(revertClasses, function (index, classCss) {
                    if (-1 === classesToRemove.indexOf(classCss)) {
                        classesToRemove.push(classCss);
                    }
                });

                classesToAdd = classesToAdd.unique();
                classesToRemove = classesToRemove.unique();

                $.each(classesToRemove, function (index, classToRemove) {
                    if (-1 !== classesToAdd.indexOf(classToRemove)) {
                        classesToRemove.remove(classToRemove);
                    }
                });

                $target.removeClass(classesToRemove.join(' ')).addClass(classesToAdd.join(' '));

                event.stopPropagation();
            })

            // Hide on mouseleave
            .on('mouseleave.frame, touchleave.frame', '[data-' + showAction + '].hover\\\/dialog,[data-' + hideAction + '].hover\\\/dialog', function (event) {
                const $self = $(this);

                if ($self.data(showAction)) {
                    $($self.data(showAction).toString().split(',')).each(function (index, dataShow) {
                        const $element = $('[data-name="' + dataShow + '"]'),
                            elementOffset = $element.offset();

                        if (true === $element.isShow()) {
                            $element.frameHide();
                        }
                    });
                }

                if ($self.data(hideAction)) {
                    $($self.data(hideAction).toString().split(',')).each(function (index, dataHide) {
                        const $element = $('[data-name="' + dataHide + '"]');

                        if (false === $element.isShow()) {
                            $element.frameShow();
                        }
                    });
                }

                event.stopPropagation();
            })

            .on('mouseleave.frame, touchleave.frame', '[data-target][data-action].hover\\\/dialog:not([data-method])', function (event) {
                const $element = $(event.target),
                    $target = $('[data-name="' + $element.data('target') + '"]'),
                    actionIndex = parseInt($element.data('action-index') ?? 0),
                    classes = [],
                    revertClasses = [];
                
                let classesToAdd = [],
                    classesToRemove = [],
                    actionCount = 0;

                $.each($element.data('action').split('|'), function (index, actions) {
                    if (1 == index)  {
                        $.each(actions.split(','), function (index, action) {
                            classes.push(action);
                        });
                    } else if(0 == index) {
                        $.each(actions.split(','), function (index, action) {
                            revertClasses.push(action);
                        });
                    }

                    actionCount++;
                });

                $.each(classes, function (index, classCss) {
                    if (-1 === classesToAdd.indexOf(classCss)) {
                        classesToAdd.push(classCss);
                    }
                });

                $.each(revertClasses, function (index, classCss) {
                    if (-1 === classesToRemove.indexOf(classCss)) {
                        classesToRemove.push(classCss);
                    }
                });

                classesToAdd = classesToAdd.unique();
                classesToRemove = classesToRemove.unique();

                $.each(classesToRemove, function (index, classToRemove) {
                    if (-1 !== classesToAdd.indexOf(classToRemove)) {
                        classesToRemove.remove(classToRemove);
                    }
                });

                $target.removeClass(classesToRemove.join(' ')).addClass(classesToAdd.join(' '));

                event.stopPropagation();
            })

            // Scroll on click or mouse over
            .on('click.frame', '[data-' + scrollAction + ']', function (event) {
                const $self = $(this);

                if ($self.data('hide')) {
                    let hidePromises = [],
                        showPromises = [],
                        $scollElements = [],
                        $hideElements = [];

                    $($self.data(scrollAction).toString().split(',')).each(function (index, data) {
                        $scollElements.push($('[data-name="' + data + '"]'));
                    });

                    $($self.data(hideAction).toString().split(',')).each(function (index, data) {
                        $hideElements.push($('[data-name="' + data + '"]'));
                    });

                    const deferredFrameHide = function($element) {
                        return $.Deferred(function(defer) {
                            $element.on('hide', function() {
                                defer.resolve(true);
                            });
                            $element.frameHide();
                        }).promise();
                    };

                    $hideElements.forEach(function ($element) {
                        hidePromises.push(deferredFrameHide($element).then());
                    });

                    $.when.apply(null, hidePromises).done(function() {
                        $scollElements.forEach(function ($scollElement) {
                            const duration = $self.data(scrollActionDuration) == undefined ? 400 : $self.data(scrollActionDuration),
                                method = $self.data('method'),
                                headerOutsite = method === 'headerOutside';

                            $scollElement.frameScrollTo($self.hasClass('scroll\/noanimation'), duration, headerOutsite);
                        });
                    });
                } else {
                    if ($self.data(scrollAction)) {
                        $($self.data(scrollAction).toString().split(',')).each(function (index, dataShow) {
                            const $element = $('[data-name="' + dataShow + '"]'),
                                duration = $self.data(scrollActionDuration) == undefined ? 400 : $self.data(scrollActionDuration),
                                method = $self.data('method'),
                                headerOutsite = method === 'headerOutside';

                            $element.frameScrollTo($self.hasClass('scroll\/noanimation'), duration, headerOutsite);
                        });
                    }
                }
            })

            // Scroll on mouse over
            .on('mouseover.frame, touchstart.frame, touchcancel.frame', '[data-' + scrollAction + '].hover\\\/dialog', function (event) {
                const $self = $(this);

                if ($self.data(scrollAction)) {
                    $($self.data(scrollAction).toString().split(',')).each(function (index, dataShow) {
                        const $element = $('[data-name="' + dataShow + '"]'),
                            duration = $self.data('scroll-duration') == undefined ? 400 : $self.data('scroll-duration'),
                            method = $self.data('method'),
                            headerOutsite = method === 'headerOutside';

                        $element.frameScrollTo($self.hasClass('scroll\/noanimation'), duration, headerOutsite);
                    });
                }
            })
        
            // Filter for select on load and on change
            .on('change.frame', 'select[data-group]:has(option[data-filter])', function (event) {
                filterSelect($(this));
            })

            // Filter when click
            .on('click.frame', '[data-filter]:not(option)', function (event) {
                const $self = $(this),
                    group = $self.data('group'),
                    name = $self.attr('name');

                if ('all' === $self.data('filter')) {
                    $('[name="' + name + '"][data-group="' + group + '"][data-filter].open').removeClass('open');
                    $('input[type="checkbox"][name="' + name + '"][data-group="' + group + '"][data-filter].open').prop('checked', false);
                    $('select[name="' + name + '"][data-group="' + group + '"]').val('').removeClass('open');
                    $self.addClass('open');
                } else {
                    $('[name="' + name + '"][data-group="' + group + '"][data-filter="all"].open').removeClass("open");
                
                    if (true === $self.hasClass('multiple')) {
                        $self.toggleClass('open');
                    } else {

                        $('input[type="checkbox"][name="' + name + '"][data-group="' + group + '"][data-filter].open:not(.multiple)').not($self).prop('checked', false);
                        $('[name="' + name + '"][data-group="' + group + '"][data-filter].open:not(.multiple)').not($self).removeClass('open');
                        $('select[name="' + name + '"][data-group="' + group + '"]:has(option[data-filter]):not(.multiple)').val('').removeClass('open');
                        $self.toggleClass('open');

                    }
                }

                filterElements(group);
            })

            // Sort for select on load and on change
            .on('change.frame', 'select[data-group]:has(option[data-sort])', function (event) {
                const $self = $(this),
                    group = $self.data('group'),
                    $selectedOption = $self.find('option[data-sort]:selected'),
                    $otherOptions = $self.find('option[data-sort]:selected');

                if (true === $self.hasClass('multiple')) {
                    if ('' !== $self.val()) {
                        if (false === $self.hasClass('open')) {
                            const $lastOpenedSorts = $('[data-sort][data-group="' + group + '"].open, select[data-group="' + group + '"] option[data-sort]:selected').not($self).not($self.find('option:selected')).sortByData('index').last();
                            let lastIndex = 0;

                            if ($lastOpenedSorts.length > 0) {
                                lastIndex = $lastOpenedSorts.data('index');
                            }

                            $self.find('option:selected').data('index', parseInt(lastIndex) + 1);
                        } else {
                            let lastIndex = $self.find('option').not(':selected').sortByData('index').last().data('index');

                            $self.find('option:selected').data('index', parseInt(lastIndex));

                            $self.find('option').not(':selected').removeData('index');
                            $self.find('option').not(':selected').removeAttr('data-index');
                        }
                    }
                } else {
                    $('[data-group="' + group + '"][data-sort].open').not($self).removeClass('open');
                    $('select[data-group="' + group + '"]:has(option[data-sort])').not($self).val('').removeClass('open');
                }

                if ('' !== $self.val()) {
                    $self.addClass('open');
                } else {
                    $self.find('option').removeData('index');
                    $self.find('option').removeAttr('data-index');
                    $self.removeClass('open');
                }

                sortElements(group);
            })

            // Sort when click
            .on('click.frame', '[data-sort]:not(option)', function (event) {
                const $self = $(this),
                    group = $self.data('group'),
                    sort = $self.data('sort'),
                    $sibling = $('[data-sort="' + sort + '"][data-group="' + group + '"].open').not($self);


                if (true === $self.hasClass('multiple')) {
                    if (false === $self.hasClass('open')) {
                        if ($sibling.length > 0) {
                            $sibling.removeClass('open');
                            $self.data('index', $sibling.data('index'));
                        } else {
                            const $lastOpenedSorts = $('[data-sort][data-group="' + group + '"].open').not($self).sortByData('index').last();
                            let lastIndex = 0;

                            if ($lastOpenedSorts.length > 0) {
                                lastIndex = $lastOpenedSorts.data('index');
                            }

                            $self.data('index', parseInt(lastIndex) + 1);
                        }
                    } else {
                        $self.removeData('index');
                        $self.removeAttr('data-index');
                    }

                    $self.toggleClass('open');
                } else {
                    $('[data-group="' + group + '"][data-sort].open').removeClass('open');
                    $('select[data-group="' + group + '"]:has(option[data-sort])').val('').removeClass('open');
                    $self.addClass('open');
                }

                sortElements(group);
            })

            // Filter by text
            .on('keyup', '.search[data-group]', function (event) {
                const $search = $(this),
                    query = $search.val(),
                    group = $search.data('group'),
                    $elements = $('aside[data-group="' + group + '"]');

                $elements.each(function(index, element) {
                    const $element = $(element);

                    if (-1 === $element.text().toLowerCase().indexOf(query.toLowerCase())) {
                        $element.addClass('hide');
                    } else {
                        $element.removeClass('hide');
                    }
                });
            })

            // Actions on scroll
            .on('scroll.frame', '[data-name] > div:first-of-type', scrollHandler)

            // Animate Element on mousemove
            .on('mousemove.frame', '[class*="effect("].move\\\/effect', function (event) {
                var $element = $(event.target),
                    x,
                    y,
                    xPercent,
                    yPercent;

                if ($element.hasClass('move/effect')) {
                    x = event.offsetX;
                    y = event.offsetY;
                } else {
                    var position = $element.position();

                    $element = $element.parents('[class*="effect("].move\\\/effect');

                    x = parseInt(position.left + event.offsetX);
                    y = parseInt(position.top + event.offsetY);
                }

                xPercent = parseInt(x / $element.width() * 1000);
                yPercent = parseInt(y / $element.height() * 1000);

                $element.css('animation-delay',  (-xPercent) + 'ms,' + (-yPercent) + 'ms');
            })

            // Animate Element on touchmove
            .on('touchmove.frame', '[class*="effect("].move\\\/effect', function (event) {
                var $element = $(event.target);

                if (false === $element.hasClass('move/effect')) {
                    $element = $element.parents('[class*="effect("].move\\\/effect');  
                }

                const offset = $element.offset(),
                    xPercent = 1000 - parseInt((event.pageX - offset.left) / $element.width() * 1000),
                    yPercent = 1000 - parseInt((event.pageY - offset.top) / $element.height() * 1000);

                $element.css('animation-delay',  (-xPercent) + 'ms,' + (-yPercent) + 'ms');

                event.preventDefault();
            })

            // Animate Element on click
            .on('click.frame', '.click\\\/effect', effectOnClickHandler)
        
            // Animate Element on mouseover
            .on('mouseover.frame', '.hover\\\/effect', function (event) {
                let $element = $(event.target);

                if (false === $element.hasClass('hover/effect')) {
                    $element = $element.parents('.hover\\\/effect');
                }

                if (false === $element.hasClass('animation')) {
                    $element.addClass('animation');

                    $element.on('animationend', function () {
                        $element.removeClass('animation');
                    });
                }
            })

            // Control slider
            .on('click.frame', '.controls[data-target] .previous', function (event) {
                const $opener = $(event.target).parents('.controls[data-target]'),
                    target = $opener.data('target'),
                    $slider = $('[data-name="' + target + '"]');

                if ($slider.length === 0) {
                    return console.error('Element with data-name "' + target + '" doesn\'t exists !');
                }

                if (typeof $slider[0].swiper === 'undefined') {
                    return console.error('Element with data-name "' + target + '" isn\'t a slider !');   
                }

                $slider[0].swiper.slidePrev();

            })

            .on('click.frame', '.controls[data-target] .next', function (event) {
                const $opener = $(event.target).parents('.controls[data-target]'),
                    target = $opener.data('target'),
                    $slider = $('[data-name="' + target + '"]');

                if ($slider.length === 0) {
                    return console.error('Element with data-name "' + target + '" doesn\'t exists !');
                }

                if (typeof $slider[0].swiper === 'undefined') {
                    return console.error('Element with data-name "' + target + '" isn\'t a slider !');   
                }

                $slider[0].swiper.slideNext();

            })

            .on('dblclick.frame', '.zoomAuto,[class*=" zoomAuto("],[class^="zoomAuto("]', function (event) {
                const $element = $(event.target)
                    coefficient = 2;

                if (false === $element.hasClass('zoomAuto') && false === $element.classStart('zoomAuto(')) {
                    return;
                }

                let zoom = parseInt($element.data('zoom')),
                    maxZoom = 2;

                if ($element.classStart('zoomAuto(')) {
                    maxZoom = parseInt($element.classParameters('zoomAuto')[0]);
                }

                $element.backgroundSize(function (dimensions) {
                    $element.backgroundPosition(function (positions) {
                        if (!$element.data('zoom') || zoom === 0) {
                            zoom = 1;
                            $element.addClass('zooming');
                        } else if (zoom == maxZoom) {
                            $element
                                .css({
                                    'background-position': '',
                                    'background-size': ''
                                })
                                .data('zoom', 0)
                                .removeClass('zooming')
                            ;
                            return;
                        } else {
                            zoom = zoom + 1;
                        }

                        let backgroundPositionX = (dimensions.width * coefficient - $element.width()) / -2 + ($element.width() / 2 - event.offsetX) * coefficient + (positions.x + (dimensions.width - $element.width()) / 2) * coefficient;
                        let backgroundPositionY = (dimensions.height * coefficient - $element.height()) / -2 + ($element.height() / 2 - event.offsetY) * coefficient + (positions.y + (dimensions.height - $element.height()) / 2) * coefficient;

                        if (backgroundPositionX > 0) {
                            backgroundPositionX = 0;
                        } else if (backgroundPositionX < - (dimensions.width * coefficient - $element.width())) {
                            backgroundPositionX = - (dimensions.width * coefficient - $element.width());
                        }

                        if (backgroundPositionY > 0) {
                            backgroundPositionY = 0;
                        } else if (backgroundPositionY < - (dimensions.height * coefficient - $element.height())) {
                            backgroundPositionY = - (dimensions.height * coefficient - $element.height());
                        }

                        $element
                            .css({
                                'background-size': (dimensions.width * coefficient) + 'px ' + (dimensions.height * coefficient) + 'px',
                                'background-position-x': backgroundPositionX,
                                'background-position-y': backgroundPositionY
                            })
                            .data('zoom', zoom)
                        ;
                    });
                }, true);
            })
            .on('mousedown', '.zoomAuto,[class*=" zoomAuto("],[class^="zoomAuto("]', function (event) {
                const $element = $(event.target);

                if (false === $element.hasClass('zooming')) {
                    return
                }

                const initialX = event.offsetX,
                    initialY = event.offsetY,
                    initialBackgroundPositionX = parseInt($element.css('background-position-x')),
                    initialBackgroundPositionY = parseInt($element.css('background-position-y'));

                $element.on('mouseup', function (event) {
                    $element
                        .off('mouseup')
                        .off('mousemove')
                        .off('mouseenter')
                    ;
                });

                $element.on('mouseout', function (event) {
                    $('body').on('mouseup', function (event) {

                        $element
                            .off('mouseup')
                            .off('mousemove')
                            .off('mouseenter')
                        ;
                        $('body').off('mouseup');
                    });
                });

                $element.on('mouseenter', function (event) {
                    $('body').off('mouseup');
                });

                $element.on('mousemove', function (event) {
                    const x = event.offsetX,
                        y = event.offsetY,
                        dimension = $element.css('background-size').replace(/px/g, '').split(' '),
                        width = parseInt(dimension[0]),
                        height = parseInt(dimension[1]);

                    let backgroundPositionX = initialBackgroundPositionX + (x - initialX),
                        backgroundPositionY = initialBackgroundPositionY + (y - initialY);

                    if (backgroundPositionX > 0 || backgroundPositionX < - (width - $element.width())) {
                        backgroundPositionX = false;
                    }
                    
                    if (backgroundPositionY > 0 || backgroundPositionY < - (height - $element.height())) {
                        backgroundPositionY = false;
                    }

                    if (false === backgroundPositionX && false === backgroundPositionY) {
                        return
                    } else if (false === backgroundPositionX) {
                        $element.css('background-position-y', backgroundPositionY);
                    } else if (false === backgroundPositionY) {
                        $element.css('background-position-x', backgroundPositionX);
                    } else {
                        $element.css('background-position', backgroundPositionX + 'px ' + backgroundPositionY + 'px');
                    }
                });
            })

            .on('pointerdown', '[class*=" zoomAutoTouch("],[class^="zoomAutoTouch("]', pointerdown_handler)
            .on('pointermove', '[class*=" zoomAutoTouch("],[class^="zoomAutoTouch("]', pointermove_handler)
            .on('pointerup', '[class*=" zoomAutoTouch("],[class^="zoomAutoTouch("]', pointerup_handler)
            .on('pointercancel', '[class*=" zoomAutoTouch("],[class^="zoomAutoTouch("]', pointerup_handler)
            .on('pointerout', '[class*=" zoomAutoTouch("],[class^="zoomAutoTouch("]', pointerup_handler)
            .on('pointerleave', '[class*=" zoomAutoTouch("],[class^="zoomAutoTouch("]', pointerup_handler)

            // Animate Element on gyro
            .on('click.frame', '[data-permission]', function () {
                function roundValue(value) { value = parseInt(value); if (1 == (value % 2)) value = value + 1; return value; }

                DeviceOrientationEvent.requestPermission()
                .then(response => {
                    if (response == 'granted') {
                        gyro.stopTracking();
                        gyro.calibrate();

                        let lastTimestamp = null;
                        const $elements = $('[class*="effect("].motion\\\/effect');

                        const refresh = function (timestamp) {
                            if (lastTimestamp !== null) {
                                const delay = timestamp - lastTimestamp;
                                var xPercent, yPercent;
                                
                                if (delay > 10) {
                                    const o = gyro.getOrientation(),
                                        factor = 3;

                                    lastTimestamp = timestamp;

                                    xPercent = roundValue(o.gamma + 50);
                                    yPercent = roundValue(o.beta + 50);
                                    zPercent = roundValue(o.gamma + 50);

                                    $elements.css('animation-delay',  (-xPercent * 10 + 250) + 'ms,' + (-yPercent * 10 + 250) + 'ms,' + (-zPercent * 10 + 250) + 'ms');
                                }
                            } else {
                                lastTimestamp = timestamp;
                            }

                            window.requestAnimationFrame(refresh);
                        };

                        window.requestAnimationFrame(refresh);
                    }
                })
                .catch(console.error);
            })
        ;

        $(window)
            
            // Actions on resize orientationchange        
            .on('resize.frame orientationchange.frame', function () {
                calculatePersistent();

                $('.position\\\(sticky\\\)').each(function (index, element) {
                    const $element = $(element),
                        elementRect = $element[0].getBoundingClientRect();
                        windowScrollTop = window.scrollY;

                    if ($element.hasClass('sticked')) {
                        const $placeholder = $('[data-uuid="' + $element.data('placeholder') + '"]')
                            placeholderRect = $placeholder[0].getBoundingClientRect();

                        $element.data('initial-top', windowScrollTop + placeholderRect.top);

                    } else {
                        $element.data('initial-top', windowScrollTop + elementRect.top);
                    }
                });

                $('aside.dialog[class*=" dropdown"],.dialog[class^="dropdown"]').frameHide();
            })

            .on('load.frame scroll.frame resize.frame orientationchange.frame', scrollHandler)
        ;

        $('[data-file]').each(function (index, element) {
            const $element = $(element),
                fileElementId = $element.data('file'),
                $file = $('#' + fileElementId),
                $form = $element.parents('form');

            $element.data('message', $element.html());

            $form.on('reset', function (event) {
                $element.html($element.data('message'));
            });
            
            if ($file.length !== 0) {
                $file.on('change', function (event) {
                    let filenames = [];

                    $.each($file.get(0).files, function (index, file) {
                        filenames.push(file.name);
                    });

                    $element.html(filenames.join(', '));
                });
            }
        });

    };

    $.frameFilter = function () {
        let groups = [];

        $('[data-group]').each(function (index, element) {
            const $element = $(element);

            groups.push($element.data('group'));
        })

        groups = groups.unique();

        $.each(groups, function (index, group) {
            filterElements(group);
        });
    };

    $.frameSort = function () {
        let groups = [];

        $('[data-group][data-sorts]').each(function (index, element) {
            const $element = $(element);

            groups.push($element.data('group'));
        })

        groups = groups.unique();

        $.each(groups, function (index, group) {
            initialSortedGroup[group] = $('[data-group="' + group + '"][data-sorts]');
            sortElements(group);
        });
    };

    $.frameClassAuto = function () {
        // $('[class*=" delayAuto("],[class^="delayAuto("]').not('aside').notParents('aside').each(function (index, elementDelayAuto) {
        //     const $elementDelayAuto = $(elementDelayAuto)
        //         delay = humanTimeToMillisecond($elementDelayAuto.classParameters('delayAuto')[0]);

        //     $(elementDelayAuto).find('[class*="motion("]').each(function (index, element) {
        //         const $element = $(element);

        //         if ($element.notClassStart('delay(')) {
        //             $element.css('animation-delay', (index * delay) + 'ms');
        //         }
        //     });
        // });

        $('[class*=" delay("],[class^="delay("]').each(function (index, element) {
            $(element).valueFromClassParametters('delay', 'animation-delay');
        });

        $('[class*=" speed("],[class^="speed("]').each(function (index, element) {
            $(element).valueFromClassParametters('speed', 'animation-duration');
        });

        $('[class*=" widthAuto("],[class^="widthAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('widthAuto', 'width');
        });

        $('[class*=" widthMaxAuto("],[class^="widthMaxAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('widthMaxAuto', 'max-width');
        });

        $('[class*=" widthMinAuto("],[class^="widthMinAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('widthMinAuto', 'min-width');
        });

        $('[class*=" heightAuto("],[class^="heightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('heightAuto', 'height');
        });

        $('[class*=" heightMaxAuto("],[class^="heightMaxAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('heightMaxAuto', 'max-height');
        });

        $('[class*=" heightMinAuto("],[class^="heightMinAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('heightMinAuto', 'min-height');
        });

        $('[class*=" paddingAuto("],[class^="paddingAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingAuto', 'padding');
        });

        $('[class*=" paddingTopAuto("],[class^="paddingTopAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingTopAuto', 'padding-top');
        });

        $('[class*=" paddingBottomAuto("],[class^="paddingBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingBottomAuto', 'padding-bottom');
        });

        $('[class*=" paddingLeftAuto("],[class^="paddingLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingLeftAuto', 'padding-left');
        });

        $('[class*=" paddingRightAuto("],[class^="paddingRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingRightAuto', 'padding-right');
        });

        $('[class*=" paddingTopBottomAuto("],[class^="paddingTopBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingTopBottomAuto', 'padding-top');
            $(element).valueFromClassParametters('paddingTopBottomAuto', 'padding-bottom');
        });

        $('[class*=" paddingLeftRightAuto("],[class^="paddingLeftRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('paddingLeftRightAuto', 'padding-left');
            $(element).valueFromClassParametters('paddingLeftRightAuto', 'padding-right');
        });

        $('[class*=" marginAuto("],[class^="marginAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginAuto', 'margin');
        });

        $('[class*=" marginTopAuto("],[class^="marginTopAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginTopAuto', 'margin-top');
        });

        $('[class*=" marginBottomAuto("],[class^="marginBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginBottomAuto', 'margin-bottom');
        });

        $('[class*=" marginLeftAuto("],[class^="marginLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginLeftAuto', 'margin-left');
        });

        $('[class*=" marginRightAuto("],[class^="marginRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginRightAuto', 'margin-right');
        });

        $('[class*=" marginTopBottomAuto("],[class^="marginTopBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginTopBottomAuto', 'margin-top');
            $(element).valueFromClassParametters('marginTopBottomAuto', 'margin-bottom');
        });

        $('[class*=" marginLeftRightAuto("],[class^="marginLeftRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('marginLeftRightAuto', 'margin-left');
            $(element).valueFromClassParametters('marginLeftRightAuto', 'margin-right');
        });

        $('[class*=" distanceTopAuto("],[class^="distanceTopAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceTopAuto', 'top');
        });

        $('[class*=" distanceBottomAuto("],[class^="distanceBottomAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceBottomAuto', 'bottom');
        });

        $('[class*=" distanceLeftAuto("],[class^="distanceLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceLeftAuto', 'left');
        });

        $('[class*=" distanceRightAuto("],[class^="distanceRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceRightAuto', 'right');
        });

        $('[class*=" distanceTopLeftAuto("],[class^="distanceTopLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceTopLeftAuto', 'top');
            $(element).valueFromClassParametters('distanceTopLeftAuto', 'left');
        });

        $('[class*=" distanceTopRightAuto("],[class^="distanceTopRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceTopRightAuto', 'top');
            $(element).valueFromClassParametters('distanceTopRightAuto', 'right');
        });

        $('[class*=" distanceBottomLeftAuto("],[class^="distanceBottomLeftAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceBottomLeftAuto', 'bottom');
            $(element).valueFromClassParametters('distanceBottomLeftAuto', 'left');
        });

        $('[class*=" distanceBottomRightAuto("],[class^="distanceBottomRightAuto("]').each(function (index, element) {
            $(element).valueFromClassParametters('distanceBottomRightAuto', 'bottom');
            $(element).valueFromClassParametters('distanceBottomRightAuto', 'right');
        });

        $('[class*=" sizeAuto("],[class^="sizeAuto("]').each(function (index, element) {
            const $element = $(element),
                sizeAuto = $element.classParameters('sizeAuto')[0],
                value = parseInt(sizeAuto);

            let unity,
                padding = (value - value * 0.05) / 2;

            if (-1 !== sizeAuto.indexOf('%')) {
                unity = '%';
            } else if (-1 !== sizeAuto.indexOf('vw')) {
                unity = 'vw';
            } else {
                return;
            }

            $element
                .cssImportant('padding-left', padding + unity)
                .cssImportant('padding-right', padding + unity)
            ;
        });
    };

    /* 
        Jquery element functions
    */
   
    $.fn.sendWithAjax = function (settings) {
        const $form = this;
        let csrfEnabled = false;

        if (undefined === settings) {
            settings = {};
        }

        if (false === $form.is('form')) {
            console.error('This element isn\'t a form.')
            return false;
        }

        if (typeof window.csrfTokenName !== 'undefined' && typeof window.csrfTokenValue !== 'undefined') {
            let $csrfInput = $form.find('[name="' + window.csrfTokenName + '"]');

            csrfEnabled = true;

            if (0 !== $csrfInput.length) {
                $csrfInput.val(window.csrfTokenValue);    
            } else {
                $csrfInput = $('<input type="hidden" name="' + window.csrfTokenName + '" value="' + window.csrfTokenValue + '"/>');
                $form.append($csrfInput);
            }
        }

        let defaultSettings = {
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: new FormData($form.get(0)),
            processData: false,
            contentType: false,
            success: function(data) {
                alert(data);
            },
            error: function(jqXHR, textStatus, errorThrown ) {
            },
        };

        let usedSettings = $.extend({}, defaultSettings, settings);

        $.ajax(usedSettings)
            .done(function( data, textStatus, jqXHR ) {
                if (true === csrfEnabled && null !== jqXHR.getResponseHeader('Csrf')) {
                    window.csrfTokenValue = jqXHR.getResponseHeader('Csrf');    
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                if (true === csrfEnabled && null !== jqXHR.getResponseHeader('Csrf')) {
                    window.csrfTokenValue = jqXHR.getResponseHeader('Csrf');    
                }

                if (302 === jqXHR.status) {
                    $.ajax({
                        url: jqXHR.getResponseHeader('X-Redirect'),
                        success: usedSettings.success
                    })
                }
            })
        ;
    }
   
    $.fn.isYoutubeVideo = function () {
        const $element = this;

        if ($element.getFirstSource() === undefined) {
            return false;
        }

        return (null !== $element.getFirstSource().match(youtubeRegex));
    };

    $.fn.getFirstSource = function () {
        const $element = this;

        return $element.find('source:first').attr('src');
    };

    $.fn.video = function () {
        const $element = this,
            isAutoplay = ($element.attr('autoplay') !== undefined),
            hasControls = ($element.attr('controls') !== undefined),
            isMuted = ($element.attr('muted') !== undefined),
            isPlaysinline =($element.attr('playsinline') !== undefined);

        if (true === $element.isYoutubeVideo()) {
            loadYoutubeApi(function () {
                player = new YT.Player($element[0], {
                // height: '360',
                // width: '640',
                videoId: getYoutubeId($element.getFirstSource()),
                playerVars: {
                    'modestbranding': 1,
                    'autoplay': isAutoplay,
                    'controls': hasControls,
                    'autohide': 1,
                    'muted': isMuted,
                    'wmode':'opaque',
                    'origin': window.location.origin 
                },
                // events: {
                //     'onReady': onPlayerReady,
                //     'onStateChange': onPlayerStateChange
                // }
                });
            });
        }
    };
   
    $.fn.groupBy = function (dataName) {
        const $elements = this;
        let groups = {};

        $elements.each(function (index, element) {
            const $element = $(element),
                dataGroups = $element.data(dataName);

            if (undefined !== dataGroups) {
                $.each(dataGroups.split(','), function (index, group) {
                    if (typeof groups[group] === 'undefined') {
                        groups[group] = [$element];
                    } else {
                        groups[group].push($element);
                    }
                });
            }
        });

        return groups;
    };

    $.fn.groupByAttr = function (attr) {
        const $elements = this;
        let groups = {};

        $elements.each(function (index, element) {
            const $element = $(element),
                attrValue = $element.attr(attr);

            if (undefined !== attrValue) {
                if (typeof groups[attrValue] === 'undefined') {
                    groups[attrValue] = [$element];
                } else {
                    groups[attrValue].push($element);
                }
            }
        });

        return groups;
    };

    $.fn.sortBy = function (criteria) {
        let criteriaIndex = 0;

        const sortByCriteria = function (a, b) {
            const $a = $(a),
                $b = $(b),
                dataA = $a.data('sorts'),
                dataB = $b.data('sorts'),
                currentCriteria = criteria[criteriaIndex];

            if (dataA[currentCriteria.sort] > dataB[currentCriteria.sort]) {
                criteriaIndex = 0;

                return currentCriteria.direction === 'desc' ? -1 : 1;
            } else if (dataA[currentCriteria.sort] < dataB[currentCriteria.sort]) {
                criteriaIndex = 0;

                return currentCriteria.direction === 'desc' ? 1 : -1;
            } else {
                if (typeof criteria[criteriaIndex + 1] !== 'undefined') {
                    criteriaIndex += 1;
                    return sortByCriteria(a, b);
                } else {
                    return 0;
                }
            }
        };
        
        return (this.sort(sortByCriteria));
    };

    $.fn.sortByData = function (dataName, direction = 'asc') {
        if (-1 === ['asc', 'desc'].indexOf(direction)) {
            console.error('direction "' + direction + '" doesn\t exists')
            return;
        }

        return (this.sort(function (a, b) {
            const $a = $(a),
                $b = $(b);

            if ($a.data(dataName) > $b.data(dataName)) {
                return direction === 'asc' ? 1 : -1;
            } else if ($a.data(dataName) < $b.data(dataName)) {
                return direction === 'asc' ? -1 : 1;
            } else {
                return 0;
            }
        }));
    };
   
    $.fn.sortByZindex = function () {
        return (this.sort(function (a, b) {
            if (parseInt(a.style.zIndex) > parseInt(b.style.zIndex)) {
                return -1;
            } else {
                return 1;
            }
        }));
    };

    $.fn.backgroundSize = function (callback, nocache = false) {
        var img = new Image(), width, height, backgroundSize = this.css('background-size').split(' ');
        const $self = $(this);

        if ($self.data('background-size') && false === nocache) {
            callback($self.data('background-size'));
            return this;
        }

        if (pxRegex.test(backgroundSize[0])) width = parseInt(backgroundSize[0]);
        if (percentRegex.test(backgroundSize[0])) width = this.parent().width() * (parseInt(backgroundSize[0]) / 100);
        if (vhRegex.test(backgroundSize[0])) width = $(window).width() / 100 * (parseInt(backgroundSize[0]));
        if (pxRegex.test(backgroundSize[1])) height = parseInt(backgroundSize[1]);
        if (percentRegex.test(backgroundSize[1])) height = this.parent().height() * (parseInt(backgroundSize[0]) / 100);
        if (vhRegex.test(backgroundSize[1])) height = $(window).height() / 100 * (parseInt(backgroundSize[1]));

        // additional performance boost, if width and height was set just call the callback and return
        if ((typeof width != 'undefined') && (typeof height != 'undefined')) {
            $self.data('background-size', { width: width, height: height });
            callback({ width: width, height: height });
            return this;
        }

        img.onload = function () {
            if (backgroundSize[0] === 'cover') {
                
                if ($self.width() / $self.height() >= width / height) {
                    width = $self.width();
                    height = parseInt(($self.width() / this.width) * this.height);
                } else {
                    height = $self.height();
                    width = parseInt(($self.height() / this.height) * this.width);
                }
            } else if (typeof width == 'undefined' && typeof height == 'undefined') {
                width = this.width;
                height = this.height;  
            } else if (typeof width == 'undefined') {
                width = height / this.height * this.width;
            } else if (typeof height == 'undefined') {
                height = width / this.width * this.height;
            }

            $self.data('background-size', { width: width, height: height });
            callback({ width: width, height: height });
        };

        img.src = this.css('background-image').replace(urlRegex, '$1');

        return this;
    };

    $.fn.dimensionClasses = function () {
        const dimensionClasses = ['height', 'width'];
        let classes = [];

        $.each(dimensionClasses, function (index, dimensionClass) {

        });

        return classes;        
    };

    $.fn.valueFromClassParametters = function (name, cssAttribute) {
        const value = this.classParameters(name)[0];

        if (value.match(numberRegex)) {
            this.cssImportant(cssAttribute, value);
        }
    };

    $.fn.backgroundPosition = function (callback) {
        const $element = this,
            backgroundPositions = $element.css('background-position').split(' ');

        $element.backgroundSize(function (dimensions) {
            positions = {};
            $.each(backgroundPositions, function (index, position) {
                if (-1 !== position.indexOf('%')) {
                    if (index == 0) {
                        positions.x = -(dimensions.width - $element.width()) * parseInt(position) / 100;
                    } else if (index == 1) {
                        positions.y = -(dimensions.height - $element.height()) * parseInt(position) / 100;
                    }
                } else if  (-1 !== position.indexOf('px')) {
                    if (index == 0) {
                        positions.x = parseInt(position);
                    } else if (index == 1) {
                        positions.y = parseInt(position);
                    } 
                }
            });

            callback(positions);
        })
    };    

    $.fn.cssImportant = function (name, value) {
        const style = this.attr('style');

        this.attr('style', (style !== undefined ? style.replace(new RegExp('\ *' + name + ':(.*?);'), '') : '') + ' ' + name + ': ' + value + ' !important;');

        return this;
    };

    $.fn.isVisible = function () {
        const rect = this[0].getBoundingClientRect();
        return (
            (rect.height > 0 || rect.width > 0) &&
            rect.bottom >= 0 &&
            rect.right >= 0 &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    $.fn.isInViewport = function () {
        const viewportHeight = (window.innerHeight || document.documentElement.clientHeight),
            viewportWidth = (window.innerWidth || document.documentElement.clientWidth),
            rect = this[0].getBoundingClientRect(),
            percentage = 0.1,
            differenceHeight = (rect.height * percentage),
            differenceWidth = (rect.width * percentage);

        return (
            (rect.height > 0 || rect.width > 0) &&
            rect.top + rect.height - differenceHeight >= 0 &&
            rect.top + differenceHeight <= viewportHeight &&
            rect.left + rect.width - differenceWidth >= 0 &&
            rect.left + differenceWidth <= viewportWidth
        );
    };

    $.fn.classParameters = function (className){
        const element = this[0],
            result = [];

        if (typeof element.className == 'string') {
            $.each(element.className.split(' '), function (index, classEach) {
                if (-1 !== classEach.indexOf(className + '(')) {
                    result.push(classEach.replace(className + '(', '').replace(')', ''));
                }
            });
        } else if (typeof element.className == 'object' && typeof element.className.baseVal !== 'undefined') {
            $.each(element.className.baseVal.split(' '), function (index, classEach) {
                if (-1 !== classEach.indexOf(className + '(')) {
                    result.push(classEach.replace(className + '(', '').replace(')', ''));
                }
            });
        }

        return result;
    };

    $.fn.classStart = function (classStart) {
        const element = this[0];

        if (typeof element.className == 'string') {
            return (-1 !== element.className.indexOf(' ' + classStart));
        }

        return false;
    };

    $.fn.notClassStart = function (classStart) {
        const element = this[0];

        if (typeof element.className == 'string') {
            return (-1 === element.className.indexOf(' ' + classStart));
        }

        return true;
    };

    $.fn.frameAnimateWithoutScroll = function (properties, duration, easing, complete) {
        complete = (typeof duration === 'function' ? duration : typeof easing === 'function' ? easing : typeof complete === 'function' ? complete : function () {});
        easing = (typeof duration === 'string' ? duration : typeof easing === 'string' ? easing : 'swing');
        duration =  ($.isNumeric(duration) ? duration : 400);

        const $element = $(this);

        $element.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function () {
            $element.stop();
            complete();
        });

        $element.animate(properties, duration, easing, function () {
            $element.off('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove');
            complete();
        });

        return this;
    };

    $.fn.slider = function (settings) {
        const $self = this;

        if (typeof settings === 'undefined' && typeof $self[0].swiper !== 'undefined') {
            return $self[0].swiper;
        }

        const $window = $(window),
            $pagination = $self.data('pagination') ? $('[data-name="' + $self.data('pagination') + '"]'): $self.children('.pagination'),
            $controls = $self.data('controls') ? $('[data-name="' + $self.data('controls') + '"]'): $self.children('.controls'),
            autoplayClass = $self.classParameters('autoplay'),
            speedClass = $self.classParameters('speed'),
            transitionClass = $self.classParameters('transition'),
            spaceClass = $self.classParameters('space'),
            slidesClass = $self.classParameters('slides'),
            spaceBeforeClass = $self.classParameters('spaceBefore'),
            spaceAfterClass = $self.classParameters('spaceAfter'),
            $thumb = $('.slider[data-target="' + $self.data('name') + '"]');

        var defaultSettings = {
            containerModifierClass : '',
            
            wrapperClass: 'slides',

            centeredSlides: $self.hasClass('slider(center)'),
            autoHeight: $self.hasClass('height(auto)'),
            watchOverflow: true,
            loop: $self.hasClass('slider(loop)'),
            freeMode: $self.hasClass('swipe(free)'),
            direction: $self.hasClass('slider(vertical)') ? 'vertical' : 'horizontal',
            a11y: false,
            watchSlidesVisibility: true,
            allowTouchMove: (false === $self.hasClass('swipe(none)')),

            slideToClickedSlide: $self.hasClass('slide(click)'),
            
            slideClass: 'slide',
            slideActiveClass: 'active',
            slideVisibleClass: 'visible',
            slidePrevClass: 'previous',
            slideNextClass: 'next',

            navigation: {
                prevEl: $controls.find('.previous [class*="button"]').get(0),
                nextEl: $controls.find('.next [class*="button"]').get(0),
                disabledClass: 'disabled', // À globaliser peu importe le cas
            },

            slideDuplicateClass: 'duplicate',
            slideDuplicateActiveClass: 'duplicate active',
            slideDuplicatePrevClass: 'duplicate previous',
            slideDuplicateNextClass: 'duplicate next',
             on: {
                init: function () {
                    this.snapGrid = [...this.slidesGrid];
                    $self.trigger('init');

                    if (0 !== $self.is('.overflow\\\(none\\\)')) {
                        $self.find('.slides > .slide.active').startMotion();
                    }

                    if (autoplayClass.length > 0 && humanTimeToMillisecond(autoplayClass[0]) && $self.is('[class*="motion("]')) {
                        this.autoplay.stop();
                    }
                },
            },
            history: false,
            hashNavigation: false,
        };

        if ($self.hasClass('slider(right)')) {
            $self.attr('dir', 'rtl');
        }

        if (false === $self.hasClass('keyboard(none)') && $self.data('target') === undefined) {
            defaultSettings.keyboard = {
                enabled: true,
                onlyInViewport: true
            };
        }


        if (true === $self.hasClass('mousewhell')) {

            const mousewehellSensitivity = $self.classParameters('mousewehell/sensitivity');

            defaultSettings.mousewheel = {
                forceToAxis: true,
                releaseOnEdges: false,
                invert: false,
                sensitivity: parseInt(mousewehellSensitivity[0]) ?? 1,
                eventsTarget: '.slider'
            };
        }

        if ($thumb.length > 0) {
            defaultSettings.thumbs = {
                slideThumbActiveClass: 'thumb-active',
                swiper: $thumb[0].swiper
            };
        }

        if ($pagination.length > 0) {
            const bulletsClass = $pagination.classParameters('bullets');

            if (false === $pagination.hasClass('scroll')) {
                defaultSettings.pagination = {
                    el: $pagination.get(0),
                    type :  $pagination.hasClass('progress') ? 'progressbar' : $pagination.hasClass('fraction') ? 'fraction' : 'bullets',
                    modifierClass: '',
                    totalClass: 'total',
                    hiddenClass: 'hidden',
                    currentClass: 'current',
                    disabledClass: 'disabled',
                    bulletClass: 'bullet',
                    bulletActiveClass: 'active',
                    dynamicBullets: false,
                    clickable: true,
                    clickableClass: 'clickable',
                    horizontalClass: 'horizontal',
                    verticalClass: 'vertical',
                    progressbarFillClass: 'position',
                    // progressbarOpposite: true,
                    renderProgressbar: function (progressbarFillClass) {
                        const swiper = this,
                        slidesLength = swiper.virtual && swiper.params.virtual.enabled ? swiper.virtual.slides.length : swiper.slides.length,
                        total = swiper.params.loop ? Math.ceil((slidesLength - (swiper.loopedSlides * 2)) / swiper.params.slidesPerGroup) : swiper.snapGrid.length;

                        let percent = 100 / total;

                        if (true === $self.hasClass('slider(vertical)')) {
                            return '<div class="bar" style="height: ' + percent + '%"></div>';
                        } else {
                            return '<div class="bar" style="width: ' + percent + '%"></div>';    
                        }
                    }
                };

                if ($pagination.hasClass('numbers') || $pagination.classParameters('numbers').length > 0) {
                    defaultSettings.pagination.bulletClass = 'number';    

                    defaultSettings.pagination.renderBullet = function (index, className) {
                      return '<div class="number">' + (index + 1) + '</div>';
                    };
                }

                if (bulletsClass.length > 0 && 'auto' === bulletsClass[0] ) {
                    defaultSettings.pagination.dynamicBullets = true;
                    defaultSettings.pagination.dynamicMainBullets = 1;
                } else if (bulletsClass.length > 0) {
                    defaultSettings.pagination.dynamicBullets = true;
                    defaultSettings.pagination.dynamicMainBullets = parseInt(bulletsClass[0]);
                }
            } else {
                defaultSettings.scrollbar = { 
                    el: $pagination.get(0),
                    draggable: true,
                    dragClass: 'bar',
                    dragSize: 'auto',
                };     
            }
        }

        let needChangeSpacesOnResize = false;

        if (spaceBeforeClass.length > 0) {
            if (false !== pixelToInt(spaceBeforeClass[0])) {
                defaultSettings.slidesOffsetBefore = pixelToInt(spaceBeforeClass[0]);
            } else if (-1 !== spaceBeforeClass[0].indexOf('vw')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetBefore = $window.width() * parseInt(spaceBeforeClass[0]) / 100;
            } else if (-1 !== spaceBeforeClass[0].indexOf('vh')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetBefore = $window.height() * parseInt(spaceBeforeClass[0]) / 100;
            } else if (-1 !== spaceBeforeClass[0].indexOf('%')) {
                needChangeSpacesOnResize = true;

                if (false === $self.hasClass('slider(vertical)')) {
                    defaultSettings.slidesOffsetBefore = $self.width() * parseInt(spaceBeforeClass[0]) / 100;
                } else {
                    defaultSettings.slidesOffsetBefore = $self.height() * parseInt(spaceBeforeClass[0]) / 100;
                }
            }

        }

        if (spaceAfterClass.length > 0) {
            if (false !== pixelToInt(spaceAfterClass[0])) {
                defaultSettings.slidesOffsetAfter = pixelToInt(spaceAfterClass[0]);
            } else if (-1 !== spaceAfterClass[0].indexOf('vw')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetAfter = $window.width() * parseInt(spaceAfterClass[0]) / 100;
            } else if (-1 !== spaceAfterClass[0].indexOf('vh')) {
                needChangeSpacesOnResize = true;

                defaultSettings.slidesOffsetAfter = $window.height() * parseInt(spaceAfterClass[0]) / 100;
            } else if (-1 !== spaceAfterClass[0].indexOf('%')) {
                needChangeSpacesOnResize = true;

                if (false === $self.hasClass('slider(vertical)')) {
                    defaultSettings.slidesOffsetAfter = $self.width() * parseInt(spaceAfterClass[0]) / 100;
                } else {
                    defaultSettings.slidesOffsetAfter = $self.height() * parseInt(spaceAfterClass[0]) / 100;
                }
            }
        }

        if (true === needChangeSpacesOnResize) {
            $(window).on('resize orientationchange', function () {
                if (-1 !== spaceBeforeClass[0].indexOf('vw')) {
                    $self[0].swiper.params.slidesOffsetBefore = $window.width() * parseInt(spaceBeforeClass[0]) / 100;
                } else if (-1 !== spaceBeforeClass[0].indexOf('vh')) {
                    $self[0].swiper.params.slidesOffsetBefore = $window.height() * parseInt(spaceBeforeClass[0]) / 100;
                } else if (-1 !== spaceBeforeClass[0].indexOf('%')) {
                    if (false === $self.hasClass('slider(vertical)')) {
                        $self[0].swiper.params.slidesOffsetBefore = $self.parent().width() * parseInt(spaceBeforeClass[0]) / 100;
                    } else {
                        $self[0].swiper.params.slidesOffsetBefore = $self.parent().height() * parseInt(spaceBeforeClass[0]) / 100;
                    }
                }

                if (-1 !== spaceAfterClass[0].indexOf('vw')) {
                    $self[0].swiper.params.slidesOffsetAfter = $window.width() * parseInt(spaceAfterClass[0]) / 100;
                } else if (-1 !== spaceAfterClass[0].indexOf('vh')) {
                    $self[0].swiper.params.slidesOffsetAfter = $window.height() * parseInt(spaceAfterClass[0]) / 100;
                } else if (-1 !== spaceAfterClass[0].indexOf('%')) {
                    if (false === $self.hasClass('slider(vertical)')) {
                        $self[0].swiper.params.slidesOffsetAfter = $self.parent().width() * parseInt(spaceAfterClass[0]) / 100;
                    } else {
                        $self[0].swiper.params.slidesOffsetAfter = $self.parent().height() * parseInt(spaceAfterClass[0]) / 100;
                    }
                }

                $self[0].swiper.update();
            });            
        }

        if (slidesClass.length > 0) {
            defaultSettings.slidesPerView = parseInt(slidesClass[0]);
        } else {
            defaultSettings.slidesPerView = 'auto';
        }

        if (spaceClass.length > 0 && false !== pixelToInt(spaceClass[0])) {
            defaultSettings.spaceBetween = pixelToInt(spaceClass[0]);
        } else {
            defaultSettings.spaceBetween = 20;
        }

        if (autoplayClass.length > 0 && humanTimeToMillisecond(autoplayClass[0])) {
            defaultSettings.autoplay = {
                delay: humanTimeToMillisecond(autoplayClass[0]),
            };
        }

        if (speedClass.length > 0 && humanTimeToMillisecond(speedClass[0])) {
            defaultSettings.speed = humanTimeToMillisecond(speedClass[0]);
        }

        if (transitionClass.length > 0 && -1 !== ['slide', 'fade', 'cube', 'coverflow', 'flip'].indexOf(transitionClass[0])) {
            defaultSettings.effect = transitionClass[0];
        }

        if ($self.parents('.slider').length > 0) {
            defaultSettings.nested = true;
            $self.addClass('nested');
        }

        defaultSettings = $.extend(true, defaultSettings, settings);

        const swiper = new Swiper($self[0], defaultSettings);

        if (typeof defaultSettings.autoplay !== 'undefined' && $self.hasClass('hover/pause')) {
            $self.on('mouseover', function (event) {
                $self.swiper.autoplay.stop();
            }).on('mouseleave', function (event) {
                $self.swiper.autoplay.start();
            });
        }

        if (0 !== $self.is('.overflow\\\(none\\\)')) {
            swiper.on('slideChangeTransitionStart', function () {
                $self.find('.slides > .slide.active').startMotion();

                $self.find('.slides > .slide:not(.active)').each(function (index, slide) {
                    const $slide = $(slide);

                    if ($slide.is('.motion\\\(repeat\\\).animation')) {
                        const motionParameters = $slide.classParameters('motion');

                        $slide
                            .removeClass('animation animation(end)')
                        ;

                        motionParameters.forEach( function (motionParameter) {
                            $slide.removeClass('motion(' + motionParameter + ')');
                        });
                        
                        window.requestAnimationFrame(function(time) {
                            window.requestAnimationFrame(function(time) {
                                motionParameters.forEach( function (motionParameter) {
                                    $slide.addClass('motion(' + motionParameter + ')');
                                });
                            });
                        });
                    }

                    $slide.find('.motion\\\(repeat\\\).animation').each(function (index, child) {
                        const $child = $(child),
                            motionParameters = $child.classParameters('motion');

                        $child
                            .removeClass('animation animation(end)')
                        ;

                        motionParameters.forEach( function (motionParameter) {
                            $child.removeClass('motion(' + motionParameter + ')');
                        });
                        
                        window.requestAnimationFrame(function(time) {
                            window.requestAnimationFrame(function(time) {
                                motionParameters.forEach( function (motionParameter) {
                                    $child.addClass('motion(' + motionParameter + ')');
                                });
                            });
                        });
                    });
                });

                if ($self.data('control')) {
                    const $slider = $('[data-name="' + $self.data('control') + '"]');

                    $slider.find('.slides > .slide.active').startMotion();

                    $slider.find('.slides > .slide:not(.active)').each(function (index, slide) {
                        const $slide = $(slide);
    
                        if ($slide.is('.motion\\\(repeat\\\).animation')) {
                            const motionParameters = $slide.classParameters('motion');
    
                            $slide
                                .removeClass('animation animation(end)')
                            ;
    
                            motionParameters.forEach( function (motionParameter) {
                                $slide.removeClass('motion(' + motionParameter + ')');
                            });
                            
                            window.requestAnimationFrame(function(time) {
                                window.requestAnimationFrame(function(time) {
                                    motionParameters.forEach( function (motionParameter) {
                                        $slide.addClass('motion(' + motionParameter + ')');
                                    });
                                });
                            });
                        }
    
                        $slide.find('.motion\\\(repeat\\\).animation').each(function (index, child) {
                            const $child = $(child),
                                motionParameters = $child.classParameters('motion');
    
                            $child
                                .removeClass('animation animation(end)')
                            ;
    
                            motionParameters.forEach( function (motionParameter) {
                                $child.removeClass('motion(' + motionParameter + ')');
                            });
                            
                            window.requestAnimationFrame(function(time) {
                                window.requestAnimationFrame(function(time) {
                                    motionParameters.forEach( function (motionParameter) {
                                        $child.addClass('motion(' + motionParameter + ')');
                                    });
                                });
                            });
                        });
                    });
                }
            });
        }

        swiper.on('slideChange', function () {
            const $slide = $($self.find(".slides > .slide").get(swiper.activeIndex));
            
            if ($slide.data(showAction)) {
                $($slide.data(showAction).split(',')).each( function (index, action) {
                    $('[data-name="' +  action +'"]').frameShow();
                });
            }

            $self.find('.slides > .slide:not(.visible.active)').each(function (index, slide) {
                const $slide = $(slide);
                
                if ($slide.data(showAction)) {
                    $($slide.data(showAction).split(',')).each( function (index, action) {
                        $('[data-name="' +  action +'"]').frameHide();
                    });
                }
            });

        });


        if ($pagination.length > 0 && $pagination.hasClass('progress')) {
            swiper.on('transitionEnd', function () {
                const swiper = this,
                    slidesLength = swiper.virtual && swiper.params.virtual.enabled ? swiper.virtual.slides.length : swiper.slides.length,
                    total = swiper.params.loop ? Math.ceil((slidesLength - (swiper.loopedSlides * 2)) / swiper.params.slidesPerGroup) : swiper.snapGrid.length;

                let percent = (100 / total) * (swiper.realIndex + 1);

                if (true === swiper.isEnd && false === swiper.params.loop) {
                    percent = 100;
                }

                if ('vertical' === swiper.params.direction) {
                    if ($pagination.hasClass('progress')) {
                        $pagination.find('.bar').height(percent + '%');
                    }
                } else {
                    if ($pagination.hasClass('progress')) {
                        $pagination.find('.bar').width(percent + '%');
                    }
                }
            });

            $pagination.removeClass('progressbar');
        }

        if ($pagination.length > 0 && ($pagination.hasClass('numbers') || $pagination.classParameters('numbers').length > 0)) {
            $pagination.removeClass('bullets');
        }



        swiper.init();

        return $self.swiper;
    };

    $.fn.removeSlider = function (settings) {
        const $self = this;

        if (typeof $self[0].swiper !== 'undefined' && null !== $self[0].swiper) {
            $self[0].swiper.destroy(true, false);
            delete $self[0].swiper;
        }
    };

    $.fn.recursiveChildren = function (callback = function (element){} ) {
        $self = this;

        $self.children().each(function (index, child) {
            const $child = $(child);

            callback($child);

            $child.recursiveChildren(callback);
        });
    };

    $.fn.isParentsOf = function ($searchedElement) {
        let elementsCollection = [];

        this.each(function (index, element) {
            const $element = $(element);

            if ($element.parents($searchedElement).length > 0) {
                elementsCollection.push($element);
            }
        });

        return $(elementsCollection);
    };

    $.fn.isNotParentsOf = function ($searchedElement) {
        let elementsCollection = [];

        this.each(function (index, element) {
            const $element = $(element);

            if ($element.parents($searchedElement).length === 0) {
                elementsCollection.push($element);
            }
        });

        return $(elementsCollection);
    };

    $.fn.notParents = function ($searchedElement) {
        let elementsCollection = [];

        this.each(function (index, element) {
            const $element = $(element);

            if ($element.has($searchedElement).length === 0) {
                elementsCollection.push($element);
            }
        });

        return $(elementsCollection);
    };

    $.fn.isShow = function () {
        const dataShowed = this.data('showed');

        if (undefined === dataShowed) {
            return false;
        } else {
            return dataShowed;
        }
    };

    $.fn.frameShow = function () {
        const $html = $('html');

        return this.each(function (index, element) {
            const $element = $(element),
                elementName = $element.data('name') ? $element.data('name').toString() : null,
                $opener = $('[data-' + showAction + '="' + elementName + '"],[data-' + showAction + '*="' + elementName + ',"]'),
                $childrenSlider = $element.find('.slider');

            if ($element.hasClass('show')) {
                return;
            }

            if (true === $element.isShow()) {
                return;
            }


            var history = false;

            $element.on('show', function (event) {
                // initialize tab
                let tabGroups = {};
                let tabGroupsAutoplay = {};

                $opener.addClass('open');
                $element.find('.dialog.tab[data-group]').each(function (index, tab) {
                    const $tab = $(tab),
                        group = $tab.data('group');

                    if (typeof tabGroups[group] === 'undefined') {
                        tabGroups[group] = [$tab];
                    } else {
                        tabGroups[group].push($tab);
                    }

                    if ($tab.classStart('autoplay(') || typeof tabGroupsAutoplay[group] !== 'undefined') {
                        if (typeof tabGroupsAutoplay[group] === 'undefined') {
                            tabGroupsAutoplay[group] = [$tab];
                        } else {
                            tabGroupsAutoplay[group].push($tab);
                        }
                    }
                });

                $.each(tabGroups, function (group, tabs) {
                    let indexToOpen = 0;

                    for (let i = 0; i < tabs.length; i++) {
                        if (tabs[i].hasClass('load/dialog')) {
                            indexToOpen = i;
                            break;
                        }
                    } 

                    tabs[indexToOpen].frameShow();
                });

                $.each(tabGroupsAutoplay, function (group, tabs) {
                    const $firstTab = $(tabs[0]),
                        autoplayClass = $firstTab.classParameters('autoplay');

                    if (autoplayClass.length > 0 && humanTimeToMillisecond(autoplayClass[0])) {
                        const delay = humanTimeToMillisecond(autoplayClass[0]);

                        window.setInterval(function (tabs) {
                            let lastIndex = 0,
                                tabToShowIndex = 0;

                            $.each(tabs, function (index, tab) {
                                if (tab.isShow()) {
                                    lastIndex = index;
                                    tab.frameHide();
                                }
                            });

                            tabs[lastIndex + 1 == tabs.length ? 0 : lastIndex + 1].frameShow();
                        }, delay, tabs);
                    }

                    return;
                });


                $element.data('observer', new IntersectionObserver(function (entries) {
                    entries.forEach( function (entry) {
                        if (true === entry.isIntersecting) {
                            const $element = $(entry.target);
                
                            $element.startMotion();
                        } else {
                            const $element = $(entry.target);
        
                            if ($element.hasClass('motion(repeat)')) {
                                const motionParameters = $element.classParameters('motion');
        
                                $element
                                    .removeClass('animation animation(end)')
                                ;
        
                                motionParameters.forEach( function (motionParameter) {
                                    $element.removeClass('motion(' + motionParameter + ')');
                                });
                                
                                window.requestAnimationFrame(function(time) {
                                    window.requestAnimationFrame(function(time) {
                                        motionParameters.forEach( function (motionParameter) {
                                        $element.addClass('motion(' + motionParameter + ')');
                                        });
                                    });
                                });
                            }
        
                            $element.find('.motion\\\(repeat\\\)').each(function (index, child) {
                                const $child = $(child),
                                    motionParameters = $child.classParameters('motion');
        
                                $child
                                    .removeClass('animation animation(end)')
                                ;
        
                                motionParameters.forEach( function (motionParameter) {
                                    $child.removeClass('motion(' + motionParameter + ')');
                                });
                                
                                window.requestAnimationFrame(function(time) {
                                    window.requestAnimationFrame(function(time) {
                                        motionParameters.forEach( function (motionParameter) {
                                            $child.addClass('motion(' + motionParameter + ')');
                                        });
                                    });
                                });
                            });
                        }
                    });
                }, {
                    //root: document,
                    rootMargin: '0px',
                    threshold: 0
                }));

                $element.children('div').find('[class*="motion("]').each(function (index, child) {
                    const $div =  $element.children('div'),
                        $child = $(child),
                        level = ($element.is('[class*="motion("]') ? 1 : 0) + ($div.is('[class*="motion("]') ? 1 : 0);

                    if (level === $child.parents('[class*="motion("]').length) {
                        $element.data('observer').observe(child);
                    }
                });

                playActionOnTarget();

                $element.off(event);
                $element.data('showed', true);

                event.stopPropagation();
            });

            if ($element.is('.dialog[class*=" dropdown"],.dialog[class^="dropdown"]')) {
                if ($element.hasClass('dropdown(full)')) {
                    $element.css({
                        'left': -$opener.offset().left - 1,
                        'top': $opener.outerHeight(false),
                    });
                } else if ($element.hasClass('dropdownTop')) {
                    $element.css('bottom', $opener.outerHeight(false));
                } else if ($element.hasClass('dropdownTopLeft')) {
                    $element.css({
                        'right': 0,
                        'bottom': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdownTopRight')) {
                    $element.css({
                        'left': 0,
                        'bottom': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdown') || $element.hasClass('dropdownBottom')) {
                    $element.css('top', $opener.outerHeight(false));
                } else if ($element.hasClass('dropdownBottomLeft')) {
                    $element.css({
                        'right': 0,
                        'top': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdownBottomRight')) {
                    $element.css({
                        'left': 0,
                        'top': $opener.outerHeight(false)

                    });
                } else if ($element.hasClass('dropdownLeft')) {
                    $element.css('right', $opener.outerWidth(false)); // - largeur de la div.dropdown
                } else if ($element.hasClass('dropdownLeftTop')) {
                    $element.css({
                        'right': $opener.outerWidth(false),
                        'bottom': 0
                    });
                } else if ($element.hasClass('dropdownLeftBottom')) {
                    $element.css({
                        'right': $opener.outerWidth(false),
                        'top': 0

                    });
                } else if ($element.hasClass('dropdownRight')) {
                    $element.css('left', $opener.outerWidth(false)); // - largeur de la div.dropdown
                } else if ($element.hasClass('dropdownRightTop')) {
                    $element.css({
                        'left': $opener.outerWidth(false),
                        'bottom': 0
                    });
                } else if ($element.hasClass('dropdownRightBottom')) {
                    $element.css({
                        'left': $opener.outerWidth(false),
                        'top': 0
                    });
                }
            }

            $element.addClass('show');
            blockScroll();

            

            if (false === $element.is('[class*="motion("]') && 0 === $element.children('div[class*="motion("]').length) {
                $element.trigger('show');
            } else {
                $element.startMotion();
            }

            $opener.addClass('open');

            if ($element.hasClass('dialog')) {
                var dialogs;
                history = true;
                
                $html.addClass('dialog');

                if (undefined === (dialogs = $html.data('dialog')) || 0 === $html.data('dialog').length) {
                    dialogs = [];
                    $html.on('click touchstart', dialogClickOutsideHandler);
                }

                if (! $element.is('[class*="persistent"]')) {
                    $element.cssImportant('z-index', (minZindex + dialogs.length));
                }

                dialogs.push(elementName);

                $html.data('dialog', dialogs);

                if ($element.hasClass('tab')) {
                    $('aside.dialog.tab[data-group="' + $element.data('group') + '"]').not($element).removeClass('load/dialog');

                    $element.addClass('load/dialog');

                    history = false;
                } else if ($element.hasClass('collapse')) {                    
                    $opener.addClass('animation');

                    history = false;
                } else if ($element.is('.dialog[class*=" dropdown"],.dialog[class^="dropdown"]')) {
                    const $divFirst = $element.children('div:first-of-type'),
                        $dropdowns = $('aside.dialog[class*=" dropdown"].show,aside.dialog[class^="dropdown"].show').not($element).notParents($element);

                    if ($element.hasClass('dropdown(full)')) {
                        $element.cssImportant('width', '100vw');
                        $element.cssImportant('z-index', '10000');
                    } else {
                        let width = 0;

                        $divFirst.recursiveChildren(function ($child) {
                            const childWidth = $child.outerWidth(true);

                            if (childWidth > width) {
                                width = childWidth;
                            }
                        });

                        width += parseInt($divFirst.css('padding-right'));
                        width += parseInt($divFirst.css('padding-left'));
                        width += parseInt($divFirst.css('margin-right'));
                        width += parseInt($divFirst.css('margin-left'));

                        $element.cssImportant('width', width + 'px');
                    }

                    $opener.addClass('animation');
                    $dropdowns.frameHide();

                    history = false;
                } else if ($element.hasClass('filter')) {
                    history = false;
                } else if ($element.is('.dialog.show.persistent\\\(top\\\)[class*="alert"]')) {
                    const $stickedElements = $('.position\\\(sticky\\\).sticked'),
                        $divFirst = $element.children('div:first-of-type');

                    $stickedElements.each(function (index, stickedElement) {
                        const $stickedElement = $(stickedElement);

                        $stickedElement.cssImportant('top', ($stickedElement.position().top + $divFirst.outerHeight()) + 'px');
                    });
                }

                calculatePersistent();

                const $sliders = $element.find('.slider');
                
                $sliders.each(function (index, slider) {
                    const $slider = $(slider);

                    if (typeof slider.swiper !== 'undefined') {
                        if ($slider.data('name') === undefined || $('.slider[data-target="' + $slider.data('name') + '"]').length == 0) {
                            slider.swiper.slideTo(0);    
                        }

                        slider.swiper.update();                        
                    }
                });
            } else if($element.hasClass('slide')) {
                const $slider = $element.parents('.slider'),
                    openerMethod = $opener.data('method'),
                    scrollMethod = $opener.data('scroll');

                $element.removeClass('show');

                if (false === $slider.is('[class*="autoplay("]')) {
                    let limit = $slider.offset().top;

                    if (undefined === openerMethod || 'headerInside' !== openerMethod) {
                        $('body > header,.persistent\\\(top\\\) > div:first-of-type,.position\\\(sticky\\\).sticked:not(.scroll\\\(outside\\\)').each(function (index, elementTop) {
                            const $elementTop = $(elementTop);

                            limit -= $elementTop.outerHeight();
                        });                    
                    }

                    if (scrollMethod !== 'none') {
                        $html.frameAnimateWithoutScroll({ scrollTop: limit }, function () {
                            var indexFund = 0;
                            $slider.find('.slides .slide').each(function (index, slide) {
                                if ($(slide).data('name') == elementName) {
                                    indexFund = index;
                                }
                            });

                            $slider.slider().slideToLoop(indexFund);

                            $element.removeClass('show');
                            $opener.removeClass('open');
                            $element.data('showed', false);
                        });
                    } else {
                        var indexFund = 0;
                        $slider.find('.slides .slide').each(function (index, slide) {
                            if ($(slide).data('name') == elementName) {
                                indexFund = index;
                            }
                        });

                        $slider.slider().slideToLoop(indexFund);

                        $element.removeClass('show');
                        $opener.removeClass('open');
                        $element.data('showed', false);
                    }
                }

            } else {
                const openerMethod = $opener.data('method');

                $element.removeClass('show');

                let limit = $element.offset().top;

                if (undefined === openerMethod || 'headerInside' !== openerMethod) {
                    $('body > header,.persistent\\\(top\\\) > div:first-of-type,.position\\\(sticky\\\).sticked:not(.scroll\\\(outside\\\)').each(function (index, elementTop) {
                        const $elementTop = $(elementTop);

                        limit -= $elementTop.outerHeight();
                    });                    
                }

                $html.frameAnimateWithoutScroll({ scrollTop: limit }, function () {
                    $element.removeClass('show');
                    $opener.removeClass('open');
                    $element.data('showed', false);
                });
            }

            $childrenSlider.each(function (index, slider) {
                $(slider).slider();
            });

            if ($element.hasClass('dialog(mandatory)')) {
                $html.addClass('mandatory');
            }

            if (true === history && false === $element.hasClass('dialog(secret)') && undefined !== elementName) {
                window.history.pushState(elementName, document.title, window.location.pathname + window.location.search + addHash(elementName));
            }

        }); // End Each
    }; // End show

    $.fn.frameHide = function () {
        const $html = $('html');

        this.each(function (index, element) {
            const $element = $(element),
                elementName = $element.data('name'),
                $opener = $('[data-' + showAction + '="' + elementName + '"],[data-' + showAction + '*="' + elementName + ',"]'),
                $firstDiv = $element.children('div:first-of-type');

            var history = false;

            $element.on('hide', function(event) {
                $element.removeClass('show');
                blockScroll();

                $firstDiv.find('[class*="motion("]').each(function (index, child) {
                    const $child = $(child),
                        motionParameters = $child.classParameters('motion');

                    $child
                        .removeClass('animation animation(end)')
                    ;

                    motionParameters.forEach( function (motionParameter) {
                        $child.removeClass('motion(' + motionParameter + ')');
                    });
                    
                    window.requestAnimationFrame(function(time) {
                        window.requestAnimationFrame(function(time) {
                            motionParameters.forEach( function (motionParameter) {
                                $child.addClass('motion(' + motionParameter + ')');
                            });
                        });
                    });
                });

                event.stopPropagation();
            });

            if (false === $element.isShow()) {
                return;
            }

            $element.data('showed', false);

            if (undefined !== $element.data('observer')) {
                $element.data('observer').disconnect();
            }

            $firstDiv.startReverseMotion();


            if (false === $element.hasClass('motion(reverse)') && false === $firstDiv.hasClass('motion(reverse)')) {
                if ($element.is('.dialog.persistent\\\(top\\\)[class*="alert"]')) {
                    const $stickedElements = $('.position\\\(sticky\\\).sticked');

                    $stickedElements.each(function (index, stickedElement) {
                        const $stickedElement = $(stickedElement);

                        $stickedElement.cssImportant('top', ($stickedElement.position().top - $firstDiv.outerHeight()) + 'px');
                    });
                }

                $element.trigger('hide');
                $element.css('z-index', '');
                blockScroll();
            }

            $opener.removeClass('open');

            if ($element.hasClass('dialog')) {
               var dialogs;
               history = true;
                
                $html.removeClass('dialog');

                if (undefined === (dialogs = $html.data('dialog'))) {
                    dialogs = [];
                }

                dialogs.remove(elementName);
                $html.data('dialog', dialogs);

                if (undefined === (dialogs = $html.data('dialog')) || 0 === $html.data('dialog').length) {
                    $html.off('click touchstart', dialogClickOutsideHandler);
                } else if ($element.is('.dialog.persistent\\\(top\\\)[class*="alert"]')) {
                    $('main').css('padding-top', '');
                    $('header').css('top', '');
                    $('.height\\\(full\\\)').css({
                        'height': '',
                        'min-height': '',
                    });
                } else if ($element.is('.dialog.persistent\\\(bottom\\\)[class*="alert"]')) {
                    $('main').css('padding-bottom', '');
                    $('footer').css('margin-bottom', '');
                    $('.height\\\(full\\\)').css({
                        'height': '',
                        'min-height': '',
                    });
                } else if ($element.is('.dialog.persistent\\\(left\\\)[class*="sidebar"]')) {
                    $('main').css('padding-left', '');
                    $('header').css({
                        'width': '',
                        'right': '',
                    });

                    $('.width\\\(full\\\)').css({
                        'width' : '',
                        'min-width': '',
                    });
                } else if ($element.is('.dialog.persistent\\\(right\\\)[class*="sidebar"]')) {
                    $('main').css('padding-right', '');
                    $('header').css({
                        'width': '',
                        'left': '',
                    });

                    $('.width\\\(full\\\)').css({
                        'width' : '',
                        'min-width': '',
                    });
                }
            } else if ($element.hasClass('collapse')) {
                $opener.removeClass('animation');
            }else if ($opener.hasClass('dropdown')) {
                $html.removeClass('dropdown');
                $opener.removeClass('animation');
            }

            if ($element.hasClass('dialog(mandatory)')) {
                $html.removeClass('mandatory');
            }

            if (true === history && false === $element.hasClass('dialog(secret)') && undefined !== elementName) {
                window.history.pushState('close', document.title, window.location.pathname + window.location.search + removeHash(elementName));
            }
        });

        return this;
    }; // End Hide

    $.fn.frameScrollTo = function (noAnimation = false, duration = 400, headerOutsite = false) {
        const $html = $('html');

        this.each(function (index, element) {
            const $element = $(element);

            let limit = $element.offset().top;

            if (0 !== limit) {
                if (false === headerOutsite) {
                    $('body > header,.persistent\\\(top\\\) > div:first-of-type,.position\\\(sticky\\\).sticked:not(.scroll\\\(outside\\\))').each(function (index, elementTop) {
                        const $elementTop = $(elementTop);

                        limit -= $elementTop.outerHeight();
                    });
                }

                if (false === noAnimation) {
                    $html.frameAnimateWithoutScroll({ scrollTop: limit }, duration);
                } else {
                    $html.get(0).scrollTop = limit;
                }                
            }
        });
    };

    $.fn.startMotion = function (force = false) {
        const $element = $(this),
            level = $element.is('[class*="motion("]') ? $element.parents('[class*="motion("]').length : null;

        const uuid = uuidv4(),
            start = (new Date()).getTime() / 1000;


        if (true === $element.is('[class*="motion("]') && false === $element.hasClass('animation(end)') && false === force) {

            $element.on('animationend', function (event) {
                if (((false === $element.hasClass('slide')) && 0 === $element.parent('aside').length) || true === force) {
                    const $children = $element.find('[class*="motion("]');

                    $children.each(function (index, child) {
                        const $child = $(child);

                        if ($child.parents('[class*="motion("]').length == (null !== level ? level + 1 : 0)) {
                            $child.startMotion();
                        }
                    });
                } else if (1 === $element.parent('aside').length) {
                    $element.parent('aside').trigger('show');
                }
                
                if ($element.is('aside') && 0 === $element.children('[class*="motion("]').length) {
                    $element.trigger('show');
                }

                $element.removeClass('animation(running)');
                
                if (false === $element.hasClass('motion(repeat)')) {
                    if ($element.hasClass('slider') && $element.is('[class*="autoplay("]')) {
                        $element.slider().autoplay.start();
                    }
                    
                    $element.addClass('animation(end)');
                }

                event.stopPropagation();
                $element.off('animationend');
            });

            
            if (0 !== $element.parents('[class*="delayAuto"]').length) {
                $element.parents('[class*="delayAuto"]').find('[class*="motion("]').not('aside').not('.animation\\\(end\\\)').notParents('aside').each(function (index, elementDelayAuto) {
                    const $elementDelayAuto = $(elementDelayAuto);
                        delay = humanTimeToMillisecond($element.parents('[class*="delayAuto"]').classParameters('delayAuto')[0] ?? '250ms') ?? 250;

                    if ($elementDelayAuto.is($element) && $element.notClassStart('delay(')) {
                        $element.css('animation-delay', (index * delay) + 'ms');
                    } 
                });
            }

            $element
                .addClass('animation')
                .css('animation-play-state', '')
            ;
        } else if (true === $element.hasClass('animation(end)') || true === force || false === $element.is('[class*="motion("]')) {
            const $children = $element.find('[class*="motion("]');

            $children.each(function (index, child) {
                const $child = $(child);
                
                if ($child.parents('[class*="motion("]').length == (null !== level ? level + 1 : 0)) {
                    $child.startMotion();
                }
            });
        } else if (true === $element.hasClass('slide')) {
            if (0 !== $element.parents('[class*="delayAuto"]').length) {
                $element.parents('[class*="delayAuto"]').find('[class*="motion("]').not('aside').not('.animation\\\(end\\\)').notParents('aside').each(function (index, elementDelayAuto) {
                    const $elementDelayAuto = $(elementDelayAuto);
                        delay = humanTimeToMillisecond($element.parents('[class*="delayAuto"]').classParameters('delayAuto')[0] ?? '250ms') ?? 250;

                    if ($elementDelayAuto.is($element) && $element.notClassStart('delay(')) {
                        $element.css('animation-delay', (index * delay) + 'ms');
                    } 
                });
            }

            $element
                .addClass('animation')
                .css('animation-play-state', '')
            ;
        }
    }

    $.fn.startReverseMotion = function () {
        const $element = $(this),
            level = $element.parents('.motion\\\(reverse\\\)').length;

        // const uuid = uuidv4(),
        //     start = (new Date()).getTime();

        //console.log({uuid: uuid, duration: (((new Date()).getTime()) - start), element: $element.get(0), function: 'startReverseMotion'});

        if ($element.is('.motion\\\(reverse\\\)')) {
            //console.log({uuid: uuid, duration: (((new Date()).getTime()) - start), element: $element.get(0), function: "$element.is('.motion\\\(reverse\\\)')"});

            $element.on('animationend', function (event) {
                //console.log({uuid: uuid, duration: (((new Date()).getTime()) - start), element: $element.get(0), function: "$element.on('animationend'"});

                const $parent = $element.parent();

                if ($parent.is('aside.motion\\\(reverse\\\)')) {
                    $parent.startReverseMotion();
                } else {
                    $parent.trigger('hide');
                }

                $element.removeClass('animation(running)');
                $element.removeClass('animation(reverse)')

                if ($element.is('aside')) {
                    //console.log({uuid: uuid, duration: (((new Date()).getTime()) - start), element: $element.get(0), function: "$element.trigger('hide')"});
                    $element.trigger('hide');
                }

                event.stopPropagation();
                $element.off('animationend');
            });

            $element.on('animationcancel', function (event) {
                const $aside = $element.is('aside') ? $element: $element.parents('aside');
                
                //console.log('animationcancel', $aside);
                
                if (0 !== $aside.length) {
                    $aside.trigger('hide');
                }
            });

            //console.log({uuid: uuid, duration: (((new Date()).getTime()) - start), element: $element.get(0), function: "$element.addClass('animation(reverse)')"});

            $element.removeClass('animation animation\(end\)');

            window.requestAnimationFrame(function(time) {
                window.requestAnimationFrame(function(time) {
                    $element.addClass('animation(reverse) animation(running)')
                });
            });

        } else {
            //console.log({uuid: uuid, duration: (((new Date()).getTime()) - start), element: $element.get(0), function: "false === $element.is('.motion\\\(reverse\\\)')"});

            const $parent = $element.parent();

            if ($parent.is('aside.motion\\\(reverse\\\)')) {
                $parent.startReverseMotion();
            } else {
                $element.removeClass('animation animation\(end\)');
                $parent.removeClass('animation animation\(end\)');
                $parent.trigger('hide');
            }
        }
    };

    $.fn.frameOpenTab = function () {
        this.each(function (index, element) {
            const $element = $(element);

            if ($element.hasClass('tab')) {
                const $otherTabsinGroups = $('.dialog.tab.show[data-group="' + $element.data('group') + '"]').not($element);

                if ($otherTabsinGroups.length === 0) {
                    $element.frameShow();
                } else {
                    $otherTabsinGroups.on('hide', function (event) {
                        $element.frameShow();
                        $otherTabsinGroups.off(event);
                    });

                    $otherTabsinGroups.frameHide();
                }
            }
        });
    };

    // Document ready
    $(document).ready(function () {
        // Initialize
        $.frameInitialize();

    }); // End document ready
})( jQuery );